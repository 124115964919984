import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { fetchClaims, cleanSearch } from "../../actions/claims";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import "../../../sass/activityfilters.scss";
import DatePicker from "react-datepicker";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  APP_ROUTES,
  CLAIMS_LISTING_COLUMNS,
  LOCAL_STORAGE_KEYS,
  NOTIFICATION_TYPES,
  RECORDS_PER_PAGE,
} from "../../constants/common";
import { facilityService } from "../../services/facilities";
import { patientService } from "../../services/patients";
import { practiceService } from "../../services/practices";
import { formatDate } from "../../utils/date";

import ClaimsListingTable from "../atom/ClaimsListingTable";
import { createNotification } from "../../utils/notificationManager";
import { claimsService } from "../../services/claims";

class ClaimsAndCollectionDashboard extends React.Component {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
    claims: PropTypes.array.isRequired,
    fetchClaims: PropTypes.func.isRequired,
    cleanSearch: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    currentPage: 1,
    fromDate: "",
    toDate: "",
    facility: {
      value: "",
      label: "Select Facility",
    },
    patient: {
      value: "",
      label: "Select Patient",
    },
    practice: {
      value: "",
      label: "Select Practice",
    },
    has_bill: "Yes",
    events: [],
    checkedClaims: [],
    checkedEventIds: [],
    allChecked: false,
  };

  componentDidMount() {
    this.props.cleanSearch({}).then((res) => {
      this.setState({ loading: false });
    });
    this.setState({ loading: true });
    this.setCurrentPage(1);
    const options = {
      page: this.state.currentPage,
      has_bill: "Yes",
    };
    this.props.fetchClaims({ params: this.addFilters(options) }).then((res) => {
      this.setState({ loading: false });
    });
  }

  loadFacility = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    return facilityService
      .getFacilities({ params: options })
      .then((response) => {
        const options = response.data.map((facility) => ({
          value: facility.id,
          label: facility.facility_name,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadPatient = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    return patientService.getPatients({ params: options }).then((response) => {
      const options = response.data.map((patient) => ({
        value: patient.id,
        label: `${patient.first_name} ${patient.last_name}`,
      }));

      return {
        options: options,
        hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
      };
    });
  };

  loadPractices = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }
    return practiceService
      .getPractices({ params: options })
      .then((response) => {
        const options = response.data.map((practice) => ({
          value: practice.id,
          label: practice.paytoname,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  addCheckedItem = (activity, event_ids) => {
    this.setState((prevState) => ({
      checkedClaims: [...prevState.checkedClaims, activity],
    }));
    this.setState((prevState) => ({
      checkedEventIds: [...prevState.checkedEventIds, event_ids],
    }));
  };

  addAllCheckedItem = () => {
    const { claims = [] } = this.props;
    this.setState({
      checkedClaims: claims.map((activity) => activity.old_bill_id),
      checkedEventIds: claims.map((activity) => activity.event_id),
      allChecked: true,
    });
  };

  removeAllCheckedItem = () => {
    this.setState({
      checkedClaims: [],
      checkedEventIds: [],
      allChecked: false,
    });
  };

  removeCheckedItem = (activityId, event_id) => {
    this.setState((prevState) => ({
      checkedClaims: prevState.checkedClaims.filter(
        (activity) => activity !== activityId
      ),
      checkedEventIds: prevState.checkedEventIds.filter(
        (event) => event !== event_id
      ),
    }));
    this.setState({ allChecked: false });
  };
  addFilters = (options) => {
    const { fromDate, toDate, facility, patient, practice, has_bill } =
      this.state;

    if (fromDate) {
      options = { ...options, from_date: formatDate(fromDate) };
    }
    if (toDate) {
      options = { ...options, to_date: formatDate(toDate) };
    }
    if (facility.value) {
      options = { ...options, facility: facility.value };
    }
    if (patient.value) {
      options = { ...options, patient: patient.value };
    }
    if (practice.value) {
      options = { ...options, practice: practice.value };
    }
    if (has_bill) {
      options = { ...options, has_bill: has_bill };
    }
    return options;
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  validateSearch = () => {
    // return true;
    //const { practice, facility } = this.state;
    const { practice } = this.state;

    if (!practice.value) {
      createNotification(NOTIFICATION_TYPES.ERROR, "Please select Practice");
      return false;
    } else {
      return true;
    }
  };

  search = () => {
    if (!this.validateSearch()) {
      return;
    }

    this.setState({ loading: true });
    this.setCurrentPage(1);
    const options = {
      page: this.state.currentPage,
    };
    this.props.fetchClaims({ params: this.addFilters(options) }).then((res) => {
      this.setState({ loading: false });
    });
  };

  resetFilter = () => {
    this.setState({ fromDate: "" });
    this.setState({ endDate: "" });

    this.setState({
      facility: {
        value: "",
        label: "Select Facility",
      },
    });
    this.setState({
      facility: {
        value: "",
        label: "Select Facility",
      },
    });
    this.setState({
      patient: {
        value: "",
        label: "Select Patient",
      },
    });
    this.setState({
      practice: {
        value: "",
        label: "Select Practice",
      },
    });
    this.setState({
      has_bill: {
        value: "",
        label: "Select Practice",
      },
    });

    this.props.cleanSearch({}).then((res) => {
      this.setState({ loading: false });
    });
  };

  list = () => {
    const { claims = [] } = this.props;
    const even = [];
    const list = claims.map((claim) => {
      let hcpcs_code;
      if (claim.service_lines) {
        if (claim.service_lines[0]) {
          if (claim.service_lines[0].hcpcs_code) {
            hcpcs_code = claim.service_lines[0].hcpcs_code;
          }
        }
      }

      even.push(claim.event_id);
      return [
        claim.old_bill_id ? claim.old_bill_id : "",
        [
          claim.old_bill_id ? claim.old_bill_id : "",
          claim.event_id ? claim.event_id : "",
        ],
        claim.event_id ? claim.event_id : "",
        [
          claim.error_in_bill ? claim.error_in_bill : "",
          claim.error_notes ? claim.error_notes : "",
        ],
        claim.event_patient ? claim.event_patient.patient_id : "",
        claim.event_patient && claim.event_patient.patient
          ? formatDate(claim.event_patient.patient.date_of_birth)
          : "",
        `${claim.event_patient && claim.event_patient.patient
          ? claim.event_patient.patient.first_name
          : ""
        } ${claim.event_patient && claim.event_patient.patient
          ? claim.event_patient.patient.last_name
          : ""
        }`,
        claim.service_date ? formatDate(claim.service_date) : "",
        claim.total_bill ? claim.total_bill : "0",
        claim.event_patient.billing_notes
          ? claim.event_patient.billing_notes
          : "",
        claim.claim_notes ? claim.claim_notes : "",
        claim.event_patient && claim.event_patient.assistant
          ? `${claim.event_patient.assistant.name} ${claim.event_patient.assistant.last_name}`
          : "",

        hcpcs_code,
        "",
        claim.event_patient &&
          claim.event_patient.patient &&
          claim.event_patient.patient.patient_insurance_data &&
          claim.event_patient.patient.patient_insurance_data.primary
          ? claim.event_patient.patient.patient_insurance_data.primary
            .description
          : "",
        claim.event_patient &&
          claim.event_patient.patient &&
          claim.event_patient.patient.patient_insurance_data &&
          claim.event_patient.patient.patient_insurance_data.secondary
          ? claim.event_patient.patient.patient_insurance_data.secondary
            .description
          : "",
        claim.event_patient && claim.event_patient.surgeon
          ? `${claim.event_patient.surgeon.first_name} ${claim.event_patient.surgeon.last_name}`
          : "",
        claim.event_patient && claim.event_patient.facility
          ? claim.event_patient.facility.facility_name
          : "",
        claim.practice ? claim.practice.paytoname : "",
      ];
    });

    // eslint-disable-next-line
    this.state.events = even;
    return list;
  };

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  goToPage = (page) => {
    this.setLoading(true);
    const options = {
      page: page < 0 ? 1 : page,
    };
    this.setCurrentPage(options.page);
    this.props
      .fetchClaims({ params: page < 0 ? options : this.addFilters(options) })
      .then((res) => {
        this.setLoading(false);
      });
  };

  createMultipleClaims = () => {
    const options = {
      checkedClaims: this.state.checkedClaims,
      checkedEventIds: this.state.checkedEventIds,
    };
    claimsService.createMultipleClaims({ options }).then((response) => {
      createNotification(NOTIFICATION_TYPES.SUCCESS, response.message);
      // var blob;
      // blob = new Blob([response.data.file_data], {
      //   type: "text/plain;charset=utf-8",
      // });
      // FileSaver.saveAs(blob, response.data.batchcode + ".X12");

      // FileSaver.saveAs(
      //   "http://127.0.0.1:8000/claims/2023-05-24/VjAuw5gJi.X12",
      //   "image.X12"
      // );
      // let fileURL =
      //   "http://127.0.0.1:8000/claims/2023-05-24/MeoYqrBXZ.X12";
      // fetch(fileURL, {
      //   method: "GET",
      //   mode: "no-cors",
      //   headers: {
      //     "Content-Type": "application/text",
      //   },
      // })
      //   .then((response) => response.blob())
      //   .then((blob) => {
      //     // Create blob link to download
      //     const url = window.URL.createObjectURL(new Blob([blob]));
      //     const link = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", `FileName.text`);

      //     // Append to html link element page
      //     document.body.appendChild(link);

      //     // Start download
      //     link.click();

      //     // Clean up and remove the link
      //     link.parentNode.removeChild(link);
      //   });
      // fetch(response).then((resp) => {
      //   resp.blob().then((blob) => {
      //     let url = window.URL.createObjectURL(blob);
      //     let a = document.createElement("a");
      //     a.href = url;
      //     a.download = "aaa.x12";
      //     a.click();
      //   });
      // });
    });
  };
  sendInvoice = () => {
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    let sum = 0;
    this.props.claims.map((claim) => (sum += parseInt(claim.total_bill)));
    const request = {
      facility: this.state.facility.value,
      patient: this.state.patient.value,
      total: sum,
      created_by: loggedInUser.id,
      events: this.state.events,
    };

    claimsService
      .createInvoice(request)
      .then((res) => {
        if (res.success) {
          this.props.history.push({
            pathname: APP_ROUTES.INVOICE_CLAIM_AND_COLLECTION,
            state: {
              activePage: "claims_and_collections",
              claims: this.props.claims,
              facility: this.state.facility.label,
              facilityId: this.state.facility.value,
              practice: this.state.practice.value,
              patient: this.state.patient.value,
              startDate: this.state.fromDate,
              endDate: this.state.toDate,
              events: this.state.events,
            },
          });
        } else {
          createNotification(NOTIFICATION_TYPES.ERROR, res.message);
        }
      })
      .catch((err) => {
        createNotification(
          NOTIFICATION_TYPES.ERROR,
          `Unable to create Invoice ${err.message}`
        );
      });
  };

  render() {
    /*const activePage =
      this.props.activePage || this.props.location.state.activePage;*/
    return (
      <div className="main_container">
        <div
          className="col-md-3 custom-sidebar-menu left_col"
          style={{
            minHeight: "100vh",
          }}
        >
          <Sidebar activePage={"claims-and-collections"} />
        </div>
        <TopNavbar />
        <div
          style={{
            minHeight: "830px",
          }}
          className="right-col"
        >
          <div className="page-title">
            <div className="title_left">
              <h3
                style={{
                  fontFamily:
                    "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "1.471",
                }}
              >
                Claims and Collections
              </h3>
            </div>
          </div>
          <div className="custom-class">
            <div
              style={{
                width: "25%",
              }}
              className="filters-column"
            >
              <label>Start Date</label>
              <DatePicker
                isClearable
                className="filter-input"
                selected={this.state.fromDate}
                onChange={(date) => this.setState({ fromDate: date })}
              />
            </div>
            <div
              style={{
                width: "25%",
              }}
              className="filters-column"
            >
              <label>End Date</label>
              <DatePicker
                isClearable
                className="filter-input"
                selected={this.state.toDate}
                onChange={(date) => this.setState({ toDate: date })}
              />
            </div>
            <div
              style={{
                width: "25%",
              }}
              className="filters-column"
            >
              <label>Facility</label>
              <AsyncPaginate
                placeholder="-- Facility --"
                value={this.state.facility}
                loadOptions={this.loadFacility}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Facility",
                  },
                ]}
                onChange={(value) => this.setState({ facility: value })}
              />
            </div>
            <div
              style={{
                width: "25%",
              }}
              className="filters-column"
            >
              <label>Patient</label>
              <AsyncPaginate
                placeholder="-- Patient --"
                value={this.state.patient}
                loadOptions={this.loadPatient}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Patient",
                  },
                ]}
                onChange={(value) => this.setState({ patient: value })}
              />
            </div>
            <div
              style={{
                width: "25%",
              }}
              className="filters-column"
            >
              <label>Practice</label>
              <AsyncPaginate
                placeholder="-- Practice --"
                value={this.state.practice}
                loadOptions={this.loadPractices}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Practice",
                  },
                ]}
                onChange={(value) => this.setState({ practice: value })}
              />
            </div>
            <div
              style={{
                width: "25%",
              }}
              className="filters-column"
            >
              <label>Has Bill</label>

              <select
                value={this.state.has_bill}
                className="filter-input"
                onChange={(event) =>
                  this.setState({ has_bill: event.target.value })
                }
              >
                <option value="">--Select Has Bill--</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="filter-footer">
              <button
                style={{
                  width: "200px",
                  margin: "23px",
                }}
                onClick={this.createMultipleClaims}
                disabled={this.state.checkedClaims.length < 1}
                className={
                  this.state.checkedClaims.length < 1
                    ? "filter-footer-disabled-button"
                    : "filter-button"
                }
              >
                Download Claim File
              </button>
              <button
                type="submit"
                onClick={this.resetFilter}
                className="filter-footer-button"
              >
                Refresh
              </button>
              <button
                type="submit"
                onClick={this.search}
                className="filter-footer-button"
              >
                Search
              </button>
            </div>
            {/* {this.props.claims.length > 0 && (
              <div className="filter-footer-double hide">
                <button
                  type="submit"
                  onClick={this.sendInvoice}
                  className="filter-footer-button"
                >
                  Create Invoice
                </button>
              </div>
            )} */}
            <div className="row">
              <div className="activities-header">
                <ClaimsListingTable
                  isLoading={this.state.loading}
                  checkedClaims={this.state.checkedClaims}
                  currentPage={this.state.currentPage}
                  allChecked={this.state.allChecked}
                  addCheckedItem={this.addCheckedItem}
                  removeCheckedItem={this.removeCheckedItem}
                  addAllCheckedItem={this.addAllCheckedItem}
                  removeAllCheckedItem={this.removeAllCheckedItem}
                  goToPage={this.goToPage}
                  headColumn={CLAIMS_LISTING_COLUMNS}
                  listing={this.list()}
                  events={this.state.events}
                  claimDetails={this.props.claims}
                  totalRecords={this.props.totalRecords}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    claims: state.claimsReducer.claims,
    totalRecords: state.claimsReducer.totalClaims,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchClaims,
      cleanSearch,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClaimsAndCollectionDashboard));
