import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {
  APP_ROUTES,
  REVENUESCHEDULER_LISTING_COLUMNS,
} from "../../constants/common";
import RevenueSchedulerListingTable from "../atom/RevenueSchedulerListingTable";
import RevenueSchedulerFilters from "../organisms/RevenueSchedulerFilters";
import { bindActionCreators } from "redux";
import { fetchBilling } from "../../actions/revenueacheduler";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

import "../../../sass/activities.scss";
import "../../../sass/dashboard.scss";
import { formatDate } from "../../utils/date";
import { scanFlowService } from "../../services/scanFlow";
import loaderImage from "../../../assets/images/CoureMax.gif";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

class RevenueSchedulerDashboard extends React.Component {
  static propTypes = {
    authInfo: PropTypes.object.isRequired,
    totalRecords: PropTypes.number.isRequired,
    billings: PropTypes.array.isRequired,
    fetchBilling: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    currentPage: this.props.location.state.currentPage || 1,
    checkedBillings: [],
    allChecked: false,
    serviceDate: "",
    serviceEndDate: "",
    patient: {},
    assistant: {},
    assignSorting: {},
    billStatus: "",
    facility: {},
    assignReg: {},
    practice: {},
    regStatus: "",
    assignCode: {},
    codingStatus: "",
    assignCharge: {},
    chargeStatus: "",
    assignClaim: {},
    claimStatus: "",
    hospContract: "",
    createdBy: {},
    insurance: {},
    pageSize: this.props.location.state.pageSize || "40",
  };

  componentDidMount() {
    this.setState({ loading: true });
    // if(this.props.location.state.scanflowfiltersoptions.assignCharge.value == "null"){
    //   alert('cahge null ');
    // }

    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
      service_date: this.state.serviceDate,
      service_end_date: this.state.serviceEndDate,
      bill_status: this.state.billStatus,
      reg_status: this.state.regStatus,
      charge_status: this.state.chargeStatus,
      coding_status: this.state.codingStatus,
      claim_status: this.state.claimStatus,
      patient: this.state.patient.value,
      assistant: this.state.assignCharge.value,
      facility: this.state.facility.value,
      practice: this.state.practice.value,
      assign_charge: this.state.assignCharge.value,
      assign_claim: this.state.assignClaim,
      assign_sorting_id: this.state.assignSorting.value,
      assign_reg_id: this.state.assignReg.value,
      assign_code_id: this.state.assignCode.value,
      created_by: this.state.createdBy.value,
      insurance: this.state.insurance,
    };
    this.props.fetchBilling({ params: options }).then((res) => {
      this.setState({ loading: false });
    });
  }
  componentWillMount() {
    let propsfacility = "";
    let propsservice_date = "";
    let propsservice_end_date = "";
    let propsbill_status = "";
    let propsreg_status = "";
    let propscharge_status = "";
    //let propsclaim_status = "";
    let propscoding_status = "";
    let propspatient = "";
    let propsassistant = "";
    let propspractice = "";
    //let propsassign_charge = "";
    let propsassign_claim = "";
    let propsassign_sorting_id = "";
    let propsassign_reg_id = "";
    let propsassign_code_id = "";
    let propshosp_contract = "";
    let propscreated_by = "";
    //let propsinsurance = "";
    if (
      this.props.location.state.scanflowfiltersoptions !== "undefined" &&
      this.props.location.state.scanflowfiltersoptions != null
    ) {
      propsservice_date =
        this.props.location.state.scanflowfiltersoptions.serviceDate;
      propsservice_end_date =
        this.props.location.state.scanflowfiltersoptions.serviceEndDate;
      propsbill_status =
        this.props.location.state.scanflowfiltersoptions.billStatus;
      propsreg_status =
        this.props.location.state.scanflowfiltersoptions.regStatus;
      propscharge_status =
        this.props.location.state.scanflowfiltersoptions.chargeStatus || "";
      propscoding_status =
        this.props.location.state.scanflowfiltersoptions.codingStatus;
      /*propsclaim_status =
        this.props.location.state.scanflowfiltersoptions.claimStatus;*/
      propspatient =
        this.props.location.state.scanflowfiltersoptions.patient || "";
      propsassistant =
        this.props.location.state.scanflowfiltersoptions.assistant || "";
      propsfacility = this.props.location.state.scanflowfiltersoptions.facility;
      propspractice =
        this.props.location.state.scanflowfiltersoptions.practice || "";
      /*propsassign_charge =
        this.props.location.state.scanflowfiltersoptions.assignCharge || "";*/
      propsassign_claim =
        this.props.location.state.scanflowfiltersoptions.assignClaim || "";
      propsassign_sorting_id =
        this.props.location.state.scanflowfiltersoptions.assignSorting || "";
      propsassign_reg_id =
        this.props.location.state.scanflowfiltersoptions.assignReg;
      propsassign_code_id =
        this.props.location.state.scanflowfiltersoptions.assignCode;
      propshosp_contract =
        this.props.location.state.scanflowfiltersoptions.hospContract;
      propscreated_by =
        this.props.location.state.scanflowfiltersoptions.createdBy;
      /*propsinsurance =
        this.props.location.state.scanflowfiltersoptions.insurance;*/

      this.setFacility(propsfacility);
      this.setServiceDate(propsservice_date);
      this.setServiceEndDate(propsservice_end_date);
      this.setBillStatus(propsbill_status);
      this.setRegStatus(propsreg_status);

      if (
        Object.keys(propscharge_status).length === 0 ||
        propscharge_status == "undefined"         //eslint-disable-line
      ) {
        propscharge_status = "";
      } else {
        this.setChargeStatus(propscharge_status);
      }
      if (
        Object.keys(propsassign_claim).length === 0 ||
        propsassign_claim == "undefined"        //eslint-disable-line
      ) {
        propsassign_claim = "";
      } else {
        this.setClaimStatus(propsassign_claim);
      }
      this.setCodingStatus(propscoding_status);
      // this.setClaimStatus(propsclaim_status);
      this.setPatient(propspatient);
      this.setAssistant(propsassistant);
      this.setPractice(propspractice);
      this.setAssignSorting(propsassign_sorting_id);
      this.setAssignReg(propsassign_reg_id);
      this.setAssignCode(propsassign_code_id);
      this.setHospContract(propshosp_contract);
      this.setCreatedBy(propscreated_by);
    }
  }

  setPageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      this.goToPage(1);
    });
  };

  addCheckedItem = (activity) => {
    this.setState((prevState) => ({
      checkedBillings: [...prevState.checkedBillings, activity],
    }));
  };

  addAllCheckedItem = () => {
    const { billings = [] } = this.props;
    this.setState({
      checkedBillings: billings.map((activity) => activity.activity_id),
      allChecked: true,
    });
  };

  removeAllCheckedItem = () => {
    this.setState({
      checkedBillings: [],
      allChecked: false,
    });
  };

  removeCheckedItem = (activityId) => {
    this.setState((prevState) => ({
      checkedBillings: prevState.checkedBillings.filter(
        (activity) => activity !== activityId
      ),
    }));
    this.setState({ allChecked: false });
  };

  goToPage = (page) => {
    this.setLoading(true);
    const options = {
      page: page < 0 ? 1 : page,
      page_size: this.state.pageSize,
    };
    this.setCurrentPage(options.page);
    this.props
      .fetchBilling({ params: this.addFilters(options) })
      .then((res) => {
        this.setLoading(false);
      });
  };

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  list = () => {
    const { billings = [] } = this.props;

    return billings.map((activity) => {
      const updatedDate = activity.updated_at
        ? formatDate(activity.updated_at).split("/")
        : "";
      let modifiedDate = "";
      //let invoicess = "";
      if (updatedDate.length > 0) {
        modifiedDate = `${updatedDate[1]}, ${months[updatedDate[0] - 1]} ${
          updatedDate[2]
        }`;
      }
      return [
        activity.activity_id,
        {
          eventId: activity.activity_id,
          patientId: activity.patient ? activity.patient.id : "",
          file:
            activity.facesheets && activity.facesheets.length > 0
              ? activity.facesheets[0].facesheet_file
              : "",
        },
        activity.event_start_date ? formatDate(activity.event_start_date) : "-",
        {
          eventId: activity.activity_id,
          patientId: activity.patient ? activity.patient.id : "",
          opId: activity.op && activity.op.length > 0 ? activity.op[0].id : "",
          file:
            activity.op && activity.op.length > 0 ? activity.op[0].op_file : "",
        },
        {
          eventId: activity.activity_id,
          patientId: activity.patient ? activity.patient.id : "",
          insuranceId:
            activity.insurace_file && activity.insurace_file.length > 0
              ? activity.insurace_file[0].id
              : "",
          file:
            activity.insurace_file && activity.insurace_file.length > 0
              ? activity.insurace_file[0].insurance_file
              : "",
        },
        {
          eventId: activity.activity_id,
          patientId: activity.patient ? activity.patient.id : "",
          insuranceId:
            activity.insurace_file && activity.insurace_file.length > 0
              ? activity.insurace_file[0].id
              : "",
          file:
            activity.insurace_file && activity.insurace_file.length > 0
              ? activity.insurace_file[0].insurance_file
              : "",
        },
        `${activity.patient ? activity.patient.first_name.toUpperCase() : ""} ${
          activity.patient ? activity.patient.last_name.toUpperCase() : ""
        }`,
        `${activity.assistant ? activity.assistant.name.toUpperCase() : ""} ${
          activity.assistant ? activity.assistant.last_name.toUpperCase() : ""
        }`,
        activity.facility ? activity.facility.facility_name.toUpperCase() : "",
        `${
          activity.practice_assignor
            ? activity.practice_assignor.name.toUpperCase()
            : ""
        } ${
          activity.practice_assignor
            ? activity.practice_assignor.last_name.toUpperCase()
            : ""
        }`,
        activity.bill_status ? activity.bill_status.toUpperCase() : "-",
        activity.patient &&
        activity.patient.patient_insurance_data &&
        activity.patient.patient_insurance_data.primary
          ? activity.patient.patient_insurance_data.primary.description.toUpperCase()
          : "-",
        activity.practice ? activity.practice.paytoname.toUpperCase() : "-",
        activity.patient ? activity.patient.id : "",
        `${activity.assign_reg ? activity.assign_reg.name.toUpperCase() : ""} ${
          activity.assign_reg ? activity.assign_reg.last_name.toUpperCase() : ""
        }`,
        activity.reg_status ? activity.reg_status.toUpperCase() : "-",
        `${
          activity.assign_coder ? activity.assign_coder.name.toUpperCase() : ""
        } ${
          activity.assign_coder
            ? activity.assign_coder.last_name.toUpperCase()
            : ""
        }`,
        activity.coding_status ? activity.coding_status.toUpperCase() : "-",
        `${
          activity.assign_charge
            ? activity.assign_charge.name.toUpperCase()
            : ""
        } ${
          activity.assign_charge
            ? activity.assign_charge.last_name.toUpperCase()
            : ""
        }`,
        activity.charge_status ? activity.charge_status.toUpperCase() : "-",
        `${
          activity.assign_claim ? activity.assign_claim.name.toUpperCase() : ""
        } ${
          activity.assign_claim
            ? activity.assign_claim.last_name.toUpperCase()
            : ""
        }`,
        activity.claim_status ? activity.claim_status.toUpperCase() : "-",
        activity.assistant && activity.assistant.region
          ? activity.assistant.region.name.toUpperCase()
          : "-",
        activity.hosp_contract ? activity.hosp_contract.toUpperCase() : "-",
        activity.cpt_codes,

        activity.notes,
        `${activity.created_by ? activity.created_by.name.toUpperCase() : ""} ${
          activity.created_by ? activity.created_by.last_name.toUpperCase() : ""
        }`,
        `${
          activity.modified_by ? activity.modified_by.name.toUpperCase() : ""
        } ${
          activity.modified_by
            ? activity.modified_by.last_name.toUpperCase()
            : ""
        }`,
        modifiedDate,
        activity.account_number ? activity.account_number : "0",
        formatDate(activity.updatedDate)
          ? formatDate(activity.updatedDate).split("/")
          : "",
        activity,
      ];
    });
  };

  setServiceDate = (date) => {
    this.setState({ serviceDate: date });
  };

  setServiceEndDate = (date) => {
    this.setState({ serviceEndDate: date });
  };

  edit = () => {
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_SCAN_FLOW,
      state: {
        activePage:
          this.props.activePage || this.props.location.state.activePage,
        checkedBillings: this.state.checkedBillings,
        currentPage: this.state.currentPage,
        pageSize: this.state.pageSize,
      },
    });
  };

  setPatient = (value) => {
    this.setState({ patient: value });
  };

  setAssistant = (value) => {
    this.setState({ assistant: value });
  };

  setAssignSorting = (value) => {
    this.setState({ assignSorting: value });
  };

  setBillStatus = (value) => {
    this.setState({ billStatus: value });
  };

  setFacility = (value) => {
    this.setState({ facility: value });
  };

  setAssignReg = (value) => {
    this.setState({ assignReg: value });
  };

  setPractice = (value) => {
    this.setState({ practice: value });
  };

  setRegStatus = (value) => {
    this.setState({ regStatus: value });
  };

  setAssignCode = (value) => {
    this.setState({ assignCode: value });
  };

  setCodingStatus = (value) => {
    this.setState({ codingStatus: value });
  };

  setAssignCharge = (value) => {
    this.setState({ assignCharge: value });
  };

  setChargeStatus = (value) => {
    this.setState({ chargeStatus: value });
  };

  setAssignClaim = (value, page) => {
    this.setState({ assignClaim: value }, () => {
      if (page) {
        this.goToPage(page);
      }
    });
  };

  setClaimStatus = (value) => {
    this.setState({ claimStatus: value });
  };

  setHospContract = (value) => {
    this.setState({ hospContract: value });
  };

  setCreatedBy = (value) => {
    this.setState({ createdBy: value });
  };
  setInsurance = (value) => {
    this.setState({ insurance: value });
  };

  addFilters = (options) => {
    const {
      serviceDate,
      serviceEndDate,
      patient,
      assistant,
      assignSorting,
      billStatus,
      facility,
      assignReg,
      practice,
      regStatus,
      assignCode,
      codingStatus,
      assignCharge,
      chargeStatus,
      assignClaim,
      claimStatus,
      hospContract,
      createdBy,
      insurance,
    } = this.state;

    if (serviceDate) {
      options = { ...options, service_date: formatDate(serviceDate) };
    }
    if (serviceEndDate) {
      options = { ...options, service_end_date: formatDate(serviceEndDate) };
    }
    if (billStatus) {
      options = { ...options, bill_status: billStatus };
    }
    if (regStatus) {
      options = { ...options, req_status: regStatus };
    }
    if (chargeStatus) {
      options = { ...options, charge_status: chargeStatus };
    }
    if (codingStatus) {
      options = { ...options, coding_status: codingStatus };
    }
    if (claimStatus) {
      options = { ...options, claim_status: claimStatus };
    }
    if (hospContract) {
      options = { ...options, hosp_contract: hospContract };
    }
    if (practice.value) {
      options = { ...options, practice: practice.value };
    }
    if (assistant.value) {
      options = { ...options, assistant: assistant.value };
    }
    if (assignCharge.value) {
      options = { ...options, assign_charge_id: assignCharge.value };
    }
    if (facility.value) {
      options = { ...options, facility: facility.value };
    }
    if (patient.value) {
      options = { ...options, patient: patient.value };
    }
    if (assignClaim.value) {
      options = { ...options, assign_claim_id: assignClaim.value };
    }
    if (assignSorting.value) {
      options = { ...options, assign_sorting_id: assignSorting.value };
    }
    if (assignReg.value) {
      options = { ...options, assign_reg_id: assignReg.value };
    }
    if (assignCode.value) {
      options = { ...options, assign_code_id: assignCode.value };
    }
    if (createdBy.value) {
      options = { ...options, created_by: createdBy.value };
    }
    if (insurance.value) {
      options = { ...options, insurance: insurance.value };
    }

    return options;
  };

  exportEnabled = () => {
    const {
      serviceDate,
      serviceEndDate,
      patient,
      assistant,
      assignSorting,
      billStatus,
      facility,
      assignReg,
      practice,
      regStatus,
      assignCode,
      codingStatus,
      assignCharge,
      chargeStatus,
      assignClaim,
      claimStatus,
      hospContract,
      createdBy,
    } = this.state;
    let enabled = false;
    if (serviceDate) {
      enabled = true;
    }
    if (serviceEndDate) {
      enabled = true;
    }
    if (billStatus) {
      enabled = true;
    }
    if (regStatus) {
      enabled = true;
    }
    if (chargeStatus) {
      enabled = true;
    }
    if (codingStatus) {
      enabled = true;
    }
    if (claimStatus) {
      enabled = true;
    }
    if (hospContract) {
      enabled = true;
    }
    if (practice.value) {
      enabled = true;
    }
    if (assistant.value) {
      enabled = true;
    }
    if (assignCharge.value) {
      enabled = true;
    }
    if (facility.value) {
      enabled = true;
    }
    if (patient.value) {
      enabled = true;
    }
    if (assignClaim.value) {
      enabled = true;
    }
    if (assignSorting.value) {
      enabled = true;
    }
    if (assignReg.value) {
      enabled = true;
    }
    if (assignCode.value) {
      enabled = true;
    }
    if (createdBy.value) {
      enabled = true;
    }
    return enabled;
  };

  search = () => {
    this.setState({ loading: true });
    this.setCurrentPage(1);
    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };

    this.props
      .fetchBilling({ params: this.addFilters(options) })
      .then((res) => {
        this.setState({ loading: false });
      });
  };

  exportBills = () => {
    const options = {};

    this.setState({ exportLoading: true });
    scanFlowService
      .exportBills({ params: this.addFilters(options) })
      .then((response) => {
        this.setState({ exportLoading: false });
      });
  };

  render() {
    const activePage =
      this.props.activePage || this.props.location.state.activePage;
    return (
      <div
        className="main_container"
        style={{
          height: "100%",
        }}
      >
        <div
          style={{
            minHeight: "125vh",
          }}
          className="col-md-3 custom-sidebar-menu"
        >
          <Sidebar activePage={activePage} />
        </div>
        <TopNavbar />
        <LoadingOverlay
          active={this.state.exportLoading}
          spinner={
            <img
              style={{
                width: "200px",
              }}
              src={loaderImage}
              alt="loading..."
            />
          }
        >
          <div
            style={{
              minHeight: "760px",
            }}
            className="right-col"
          >
            <div className="page-title">
              <div
                className="title_left"
                style={{
                  width: "100%",
                }}
              >
                <h3
                  style={{
                    fontFamily:
                      "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                    fontSize: "24px",
                    fontWeight: "400",
                    lineHeight: "1.471",
                  }}
                >
                  Revenue Scheduler
                </h3>
              </div>
            </div>
            <RevenueSchedulerFilters
              serviceDate={this.state.serviceDate}
              serviceEndDate={this.state.serviceEndDate}
              setServiceDate={this.setServiceDate}
              setServiceEndDate={this.setServiceEndDate}
              patient={this.state.patient}
              setPatient={this.setPatient}
              assistant={this.state.assistant}
              setAssistant={this.setAssistant}
              assignSorting={this.state.assignSorting}
              setAssignSorting={this.setAssignSorting}
              billStatus={this.state.billStatus}
              setBillStatus={this.setBillStatus}
              facility={this.state.facility}
              setFacility={this.setFacility}
              assignReg={this.state.assignReg}
              setAssignReg={this.setAssignReg}
              practice={this.state.practice}
              setPractice={this.setPractice}
              regStatus={this.state.regStatus}
              setRegStatus={this.setRegStatus}
              assignCode={this.state.assignCode}
              setAssignCode={this.setAssignCode}
              codingStatus={this.state.codingStatus}
              setCodingStatus={this.setCodingStatus}
              assignCharge={this.state.assignCharge}
              setAssignCharge={this.setAssignCharge}
              chargeStatus={this.state.chargeStatus}
              setChargeStatus={this.setChargeStatus}
              assignClaim={this.state.assignClaim}
              setAssignClaim={this.setAssignClaim}
              claimStatus={this.state.claimStatus}
              setClaimStatus={this.setClaimStatus}
              hospContract={this.state.hospContract}
              setHospContract={this.setHospContract}
              createdBy={this.state.createdBy}
              setCreatedBy={this.setCreatedBy}
              setInsurance={this.setInsurance}
              search={this.search}
              exportEnabled={this.exportEnabled}
              exportBills={this.exportBills}
            />
            <div className="filter-footer-header">
              <button
                style={{
                  width: "206px",
                }}
                onClick={this.edit}
                disabled={this.state.checkedBillings.length < 1}
                className={
                  this.state.checkedBillings.length < 1
                    ? "filter-footer-disabled-button"
                    : "filter-button"
                }
              >
                Edit
              </button>
            </div>
            <div className="row">
              <div className="activities-header">
                <LoadingOverlay
                  active={this.state.loading}
                  spinner={
                    <img
                      style={{
                        width: "200px",
                      }}
                      src={loaderImage}
                      alt="loading..."
                    />
                  }
                >
                  <RevenueSchedulerListingTable
                    checkedBillings={this.state.checkedBillings}
                    isLoading={this.state.loading}
                    allChecked={this.state.allChecked}
                    currentPage={this.state.currentPage}
                    goToPage={this.goToPage}
                    addCheckedItem={this.addCheckedItem}
                    removeCheckedItem={this.removeCheckedItem}
                    addAllCheckedItem={this.addAllCheckedItem}
                    removeAllCheckedItem={this.removeAllCheckedItem}
                    headColumn={REVENUESCHEDULER_LISTING_COLUMNS}
                    listing={this.list()}
                    totalRecords={this.props.totalRecords}
                    setPageSize={this.setPageSize}
                    pageSize={this.state.pageSize}
                    scanflowfiltersoptions={this.state}
                  />
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    billings: state.billingReducer.billings,
    totalRecords: state.billingReducer.totalBillings,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchBilling,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RevenueSchedulerDashboard));
