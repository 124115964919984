import React from "react";
import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";
import loaderImage from "../../../assets/images/CoureMax.gif";
import editIcon from "../../../assets/images/edit.png";
import {LOCAL_STORAGE_KEYS} from "../../constants/common";
import {batchService} from "../../services/batch";

export default class PaymentPostingListing extends React.Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        headColumn: PropTypes.array.isRequired,
        listing: PropTypes.array.isRequired,
        batch: PropTypes.number.isRequired,
    };

    state = {
        postings: [],
        eventId: '',
        loading: false,
    };

    renderHeadColumn = () => {
        const { headColumn } = this.props;

        const header = headColumn.map((column, index) => {
            return (
                <th className="listing-table-head-column">
                    {column}
                </th>
            );
        });

        return header;
    };

    editPosting = (id, paid) => this.props.editPosting(id, paid);

    renderPostingDetail = (eventId) => {
        this.setState({
            eventId: eventId,
            postings: [],
            loading: true,
        }, () => {
            batchService
                .getPostingDetail(this.props.batch)
                .then(response => {
                    this.setState({
                        postings: response.data.map(posting => {
                            if (eventId === posting.event_id) {
                                return [
                                    posting.cpt || '',
                                    posting.cpt_charge || '',
                                    posting.paid || '',
                                    parseInt(posting.cpt_charge) - parseInt(posting.paid),
                                    posting.id,
                                ];
                            }
                            return [];
                        }),
                        loading: false,
                    });
                });
        });
    };

    renderPostingHeader = () => (
        <>
            <td style={{
                color: 'white',
            }}
                className="listing-table-head-column">CPT</td>
            <td style={{
                color: 'white',
            }}
                className="listing-table-head-column">Total Charge</td>
            <td style={{
                color: 'white',
            }}
                className="listing-table-head-column">Paid Amount</td>
            <td style={{
                color: 'white',
            }}
                className="listing-table-head-column">Balance</td>
            <td style={{
                color: 'white',
            }}
                className="listing-table-head-column"
                colSpan="2">
                Action
            </td>
        </>
    );

    renderPostingList = () => {
        const { postings } = this.state;
        const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
        return postings.map(posting => {
            const item = posting.map((listItem, index) => {
                if (index < posting.length - 1)  {
                    return (
                        <td className="listing-table-column">
                            {listItem}
                        </td>
                    );
                } else {
                    return (
                        <td className="listing-table-column">
                            {loggedInUser.isUpdateAccess && (
                                <img
                                    src={editIcon}
                                    onClick={() => {this.editPosting(listItem, posting[2])}}
                                    alt="edit"
                                />
                            )}
                        </td>
                    );
                }
            });

            return (
                <tr className="listing-table-rows">
                    {item}
                </tr>
            );
        });
    };

    renderList = () => {
        const { listing = [] } = this.props;
        return listing.length > 0 && listing.map(listRow => {
            const item = listRow.map((listItem, index) => {         //eslint-disable-line
                if (index < listRow.length - 1)  {
                    return (
                        <td style={{
                            lineHeight: '40px',
                        }}
                            onClick={() => this.renderPostingDetail(listRow[1])}
                            className="listing-table-column">
                            {listItem}
                        </td>
                    );
                }
            });

            return (
                <>
                    <tr className="listing-table-rows">
                        {item}
                    </tr>
                    {this.state.postings.length > 0 && this.state.eventId === listRow[1] && (
                        <tr style={{
                            backgroundColor: '#475f87',
                        }}
                            className="listing-table-rows">
                            {this.renderPostingHeader()}
                        </tr>
                    )}
                    {this.state.postings.length > 0 && this.state.eventId === listRow[1] && (
                        this.renderPostingList()
                    )}
                </>
            );
        });
    };

    render() {
        return (
            <div className="x_panel">
                <div className="x_content">
                    <div className="table-responsive">
                        <LoadingOverlay
                            active={this.props.isLoading || this.state.loading}
                            spinner={<img
                                style={{
                                    width: '200px',
                                }}
                                src={loaderImage}
                                alt="loading..."
                            />}>
                            <table className="listing-table">
                                <thead className="listing-table-head">
                                    <tr>
                                        {this.renderHeadColumn()}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderList()}
                                </tbody>
                            </table>
                        </LoadingOverlay>
                    </div>
                </div>
            </div>
        );
    }
}