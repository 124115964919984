import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import '../../../sass/notificationlist.scss';
import {APP_ROUTES} from "../../constants/common";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {formatDate} from "../../utils/date";

class NotificationList extends React.Component {

    static propTypes = {
        notifications: PropTypes.array,
        showNotification: PropTypes.bool,
        toggleNotification: PropTypes.func,
        totalNotifications: PropTypes.number.isRequired,
        navigateToNotificationPage: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.wrapperRef = null;
    }

    setWrapperRef = element => {
        this.wrapperRef = element;
    };

    handleClickOutside = event => {
        const { toggleNotification } = this.props;
        if (this.wrapperRef && !this.wrapperRef.contains(event.target) && event.target.localName !== 'svg') {
            toggleNotification(false);
        }
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    navigateToEvent = (activityId) => {
        this.props.history.push({
            pathname: APP_ROUTES.EDIT_ACTIVITY,
            state: {
                activePage: 'activities',
                activityId,
            }
        });
    };

    render() {
        const {
            notifications,
            showNotification,
            navigateToNotificationPage,
            totalNotifications,
        } = this.props;
        return (
            <div ref={this.setWrapperRef}>
                {showNotification && (
                    <div className="notification-bar">
                        <div className="notifications-label">
                            Notifications
                        </div>
                        {notifications.map(notification => (
                            <div onClick={() => this.navigateToEvent(notification.event.old_event_id)}
                                className="notification-item">
                                <div className="notification-item-type">
                                    {notification.title}
                                        <span className="notification-item-date">{moment(notification.created_at).fromNow()}</span>
                                    {notification.is_read == 0 && (     //eslint-disable-line
                                        <span className='dot' />
                                    )}
                                </div>
                                <div className="notification-item-message">
                                    {notification.notif_msg} by
                                    <span style={{
                                        fontWeight: '600',fontSize:'12px'
                                    }}>{` ${notification.event.user.name} ${notification.event.user.last_name} `}</span> at
                                    <span style={{
                                        fontWeight: '600',fontSize:'12px'
                                    }}>{` ${notification.event.facility ? notification.event.facility.facility_name : ''} `}</span>
                                </div>
                                <div className="notification-item-dos">
                                    <span style={{
                                        fontWeight: '600',
                                        color: '#ffa500',
                                        fontSize:'12px',
                                    }}>
                                        Date of Service: {formatDate(notification.event.event_start_date)}
                                    </span>
                                </div>
                            </div>
                        ))}
                        {totalNotifications > 5 && (
                            <div className="notification-button-container">
                                <button
                                    className="notification-btn notification-btn-square notification-btn-primary"
                                    onClick={navigateToNotificationPage}>
                                    See All
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}


export default connect(
    null,
    null,
)(withRouter(NotificationList));