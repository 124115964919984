import React from "react";
import {
  APP_ROUTES,
  INVOICING_LISTING_COLUMNS,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { bindActionCreators } from "redux";
import { fetchInvoicing } from "../../actions/invoicing";
import InvoicingListingTable from "../atom/InvoicingListingTable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { invoicingService } from "../../services/invoicing";
import PropTypes from "prop-types";
import InvoicingFilters from "../organisms/InvoicingFilters";
import { formatDate } from "../../utils/date";
import { AsyncPaginate } from "react-select-async-paginate";

class InvoicingDashboard extends React.Component {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
    fetchInvoicing: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    allChecked: false,
    checkedInvoices: [],
    currentPage: 1,
    searchByName: "",
    fromDate: "",
    toDate: "",
    region: {
      value: "",
      label: "Select Region",
    },
    assistant: {
      value: "",
      label: "Select Assistant",
    },
    surgeon: {
      value: "",
      label: "Select Surgeon",
    },
    facility: {
      value: "",
      label: "Select Facility",
    },
    patient: {
      value: "",
      label: "Select Patient",
    },
    status: {
      value: "",
      label: "Select Status",
    },
    createdBy: {
      value: "",
      label: "Select createdBy",
    },
    flipAssistant: false,
    pageSize: "10",
  };

  componentDidMount() {
    this.setState({ loading: true });
    /*const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };*/
  }
  componentWillMount() {
    this.setState({ loading: true });
    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };
    this.props.fetchInvoicing({ params: options }).then((res) => {
      this.setState({ loading: false });
    });
  }
  setPageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      this.goToPage(1);
    });
  };
  addCheckedItem = (invoice) => {
    this.setState((prevState) => ({
      checkedInvoices: [...prevState.checkedInvoices, invoice],
    }));
  };
  addAllCheckedItem = () => {
    const { invoicing = [] } = this.props;

    this.setState({
      checkedInvoices: invoicing.map((invoice) => invoice.id),
      allChecked: true,
    });
  };

  removeAllCheckedItem = () => {
    this.setState({
      checkedInvoices: [],
      allChecked: false,
    });
  };

  removeCheckedItem = (invoiceId) => {
    this.setState((prevState) => ({
      checkedInvoices: prevState.checkedInvoices.filter(
        (invoice) => invoice !== invoiceId
      ),
    }));
    this.setState({ allChecked: false });
  };

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  goToPage = (page) => {
    this.setLoading(true);
    this.setCurrentPage(page);

    const options = {
      page: page < 0 ? 1 : page,
      page_size: this.state.pageSize,
    };
    this.props.fetchInvoicing({ params: options }).then((res) => {
      this.setLoading(false);
    });
  };

  showAdd = () => this.props.history.push(APP_ROUTES.ADD_FACILITY);

  resetFacilitySearch = () => {
    this.setState({
      searchByName: "",
    });
  };

  list = () => {
    let { invoicing = [] } = this.props;
    if (invoicing.length > 0) {
      const lastIndex = invoicing.length - 1;
      const lastElementLength = invoicing[lastIndex].length;
      if (lastElementLength > 0) {
        invoicing = invoicing.slice(0, -1);
      }
    }
    return invoicing.map((invoice) => {
      return [
        invoice.id,
        invoice.account_number ? invoice.account_number : 0,
        invoice.dos,
        invoice.patient.first_name.toUpperCase(),
        invoice.dob,
        invoice.insurance,
        invoice.assistant.name,
        invoice.surgeon.first_name,
        invoice.facility.facility_name.toUpperCase(),
        invoice.modified_by.name.toUpperCase(),
        invoice.fee,
        invoice.status,
        invoice.id,
      ];
    });
  };

  showManageInvoicing = (activityId) =>
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_INVOICING,
      state: {
        activityId,
        currentPage: this.state.currentPage,
        pageSize: this.state.pageSize,
      },
    });

  deleteInvoicing = (invoice) => {
    invoicingService.deleteInvoicing(invoice).then((response) => {
      this.goToPage(1);
    });
  };

  createMultipleInvoice = () => {
    const options = {
      checkedInvoices: this.state.checkedInvoices,
      facility_name: this.state.facility.label,
    };
    invoicingService.exportInvoicing({ options }).then((response) => {
      // this.goToPage(1);
    });
  };
  updateInvoiceStatus = (value, page) => {
    const options = {
      checkedInvoices: this.state.checkedInvoices,
      status: value,
    };
    const filterInvoice = this.props.invoicing.filter((invoice) =>
      this.state.checkedInvoices.includes(invoice.id)
    );
    //const { invoicing = [] } = this.props;
    filterInvoice.map((invoice, index) => {     //eslint-disable-line
      invoice["status"] = options.status.value;
    });
    invoicingService.multiStatusUpdate({ options }).then((res) => {
      if (res.data.success) {
        createNotification(NOTIFICATION_TYPES.SUCCESS, res.message);
        this.props.history.push({
          pathname: APP_ROUTES.INVOICING_DASHBOARD,
        });
      } else {
        createNotification(NOTIFICATION_TYPES.SUCCESS, res.message);

        this.props.history.push({
          pathname: APP_ROUTES.INVOICING_DASHBOARD,
          state: {
            currentPage: this.props.location.state.currentPage,
            pageSize: this.props.location.state.pageSize,
          },
        });
      }
      this.setLoading(false);
    });
  };

  loadStatus = () => {
    const options = [
      {
        value: "NOT STARTED",
        label: "NOT STARTED",
      },
      {
        value: "SUBMITTED",
        label: "SUBMITTED",
      },
      {
        value: "CORRECTED CLAIM",
        label: "CORRECTED CLAIM",
      },
      {
        value: "PAPER CLAIM",
        label: "PAPER CLAIM",
      },
      {
        value: "RECONSIDERATION",
        label: "RECONSIDERATION",
      },
      {
        value: "FOLLOW-UP",
        label: "FOLLOW-UP",
      },
      {
        value: "APPEAL 1",
        label: "APPEAL 1",
      },
      {
        value: "APPEAL 2",
        label: "APPEAL 2",
      },
      {
        value: "HOLD TO BILL",
        label: "HOLD TO BILL",
      },
      {
        value: "BILL PATIENT",
        label: "BILL PATIENT",
      },
      {
        value: "BILL HOSPITAL",
        label: "BILL HOSPITAL",
      },
      {
        value: "BILL PHYSICIAN",
        label: "BILL PHYSICIAN",
      },
      {
        value: "REVIEW ADJUSTMENT",
        label: "REVIEW ADJUSTMENT",
      },
      {
        value: "CLOSED",
        label: "CLOSED",
      },
      {
        value: "CLOSED NON-BILL",
        label: "CLOSED NON-BILL",
      },
      {
        value: "CLOSED ADJUSTMENT",
        label: "CLOSED ADJUSTMENT",
      },
      {
        value: "CLOSED HC-2nd Scrub",
        label: "CLOSED HC-2nd Scrub",
      },
    ];

    return {
      options: options,
      hasMore: false,
    };
  };

  setFromDate = (date) => {
    this.setState({ fromDate: date });
  };

  setToDate = (date) => {
    this.setState({ toDate: date });
  };

  setRegion = (value) => {
    this.setState({
      region: value,
      flipAssistant: !this.state.flipAssistant,
    });
  };

  setAssistant = (value) => {
    this.setState({ assistant: value });
  };

  setSurgeon = (value) => {
    this.setState({ surgeon: value });
  };
  setSearchInvoice = (value) => {
    this.setState({ searchByName: value });
  };
  setFacility = (value) => {
    this.setState({ facility: value });
  };

  setPatient = (value) => {
    this.setState({ patient: value });
  };

  setCreatedBy = (value) => {
    this.setState({ createdBy: value });
  };

  setStatus = (value, page) => {
    this.setState({ status: value }, () => {
      if (page) {
        this.goToPage(page);
      }
    });
  };

  setFlipAssistant = (value) => {
    this.setState({ flipAssistant: value });
  };
  setFromDate = (date) => {
    this.setState({ fromDate: date });
  };

  addFilters = (options) => {
    const {
      fromDate,
      toDate,
      region,
      assistant,
      surgeon,
      facility,
      patient,
      status,
      createdBy,
      searchByName,
    } = this.state;
    if (fromDate) {
      options = { ...options, from_date: formatDate(fromDate) };
    }
    if (toDate) {
      options = { ...options, to_date: formatDate(toDate) };
    }
    if (region.value) {
      options = { ...options, company_id: region.value };
    }
    if (assistant.value) {
      options = { ...options, assistant_id: assistant.value };
    }
    if (surgeon.value) {
      options = { ...options, physician_id: surgeon.value };
    }
    if (facility.value) {
      options = { ...options, facility_id: facility.value };
    }
    if (patient.value) {
      options = { ...options, patient_id: patient.value };
    }
    if (status.value) {
      options = { ...options, status: status.value };
    }
    if (createdBy.value) {
      options = { ...options, created_by: createdBy.value };
    }
    if (searchByName) {
      options = { ...options, invoicenumber: searchByName };
    }
    return options;
  };

  search = () => {
    this.setState({ loading: true });
    this.setCurrentPage(1);
    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };

    this.props
      .fetchInvoicing({ params: this.addFilters(options) })
      .then((res) => {
        this.setState({ loading: false });
      });
  };
  render() {
    const activePage =
      this.props.activePage || this.props.location.state.activePage;
    /*const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );*/

    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={activePage} />
        </div>
        <TopNavbar />

        <div
          style={{
            minHeight: "760px",
          }}
          className="right-col"
        >
          <InvoicingFilters
            goToPage={this.goToPage}
            exportEnabled={this.exportEnabled}
            search={this.search}
            setFromDate={this.setFromDate}
            setToDate={this.setToDate}
            setRegion={this.setRegion}
            setAssistant={this.setAssistant}
            setSurgeon={this.setSurgeon}
            setSearchInvoice={this.setSearchInvoice}
            setFacility={this.setFacility}
            setPatient={this.setPatient}
            setStatus={this.setStatus}
            setFlipAssistant={this.setFlipAssistant}
            setCreatedBy={this.setCreatedBy}
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            region={this.state.region}
            assistant={this.state.assistant}
            surgeon={this.state.surgeon}
            facility={this.state.facility}
            patient={this.state.patient}
            status={this.state.status}
            createdBy={this.state.createdBy}
            flipAssistant={this.state.flipAssistant}
          />

          {/* <div className="page-title">
            <div className="title_left">
              <h3
                style={{
                  fontFamily:
                    "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "1.471",
                }}
              >
                INVOICING
              </h3>
            </div>
          </div> */}

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {/* <div
              style={{
                paddingLeft: "0",
              }}
              className="col-md-3 mrgn-btm10"
            >
              <label>Invoice Number</label>
              <input
                type="text"
                className="code-input"
                value={this.state.searchByName}
                onChange={(event) => {
                  this.setState({ searchByName: event.target.value });
                }}
              />
            </div> */}
          </div>
          <div className="filter-footer">
            <button
              style={{
                width: "200px",
                margin: "23px",
              }}
              onClick={this.createMultipleInvoice}
              disabled={this.state.checkedInvoices.length < 1}
              className={
                this.state.checkedInvoices.length < 1
                  ? "filter-footer-disabled-button"
                  : "filter-button"
              }
            >
              Download Selected Invoice's
            </button>
            <div
              style={{
                width: "200px",
                margin: "23px",
                float: "right",
              }}
            >
              <AsyncPaginate
                placeholder="-- Update Status --"
                value={this.props.status}
                loadOptions={this.loadStatus}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Status",
                  },
                ]}
                isDisabled={this.state.checkedInvoices.length < 1}
                onChange={(value) => this.updateInvoiceStatus(value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="activities-header">
              <InvoicingListingTable
                checkedInvoices={this.state.checkedInvoices}
                allChecked={this.state.allChecked}
                addCheckedItem={this.addCheckedItem}
                removeCheckedItem={this.removeCheckedItem}
                addAllCheckedItem={this.addAllCheckedItem}
                removeAllCheckedItem={this.removeAllCheckedItem}
                isLoading={this.state.loading}
                currentPage={this.state.currentPage}
                goToPage={this.goToPage}
                edit={this.showManageInvoicing}
                delete={this.deleteInvoicing}
                headColumn={INVOICING_LISTING_COLUMNS}
                listing={this.list()}
                totalRecords={this.props.totalRecords}
                setPageSize={this.setPageSize}
                pageSize={this.state.pageSize}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    invoicing: state.invoicingReducer.invoicing,
    totalRecords: state.invoicingReducer.totalInvoicing,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInvoicing,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoicingDashboard));
