import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchPractice } from "../../actions/practice";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { PRACTICE_LISTING_COLUMNS, APP_ROUTES } from "../../constants/common";
import PracticeListingTable from "../atom/PracticeListingTable";
import { practiceService } from "../../services/practices";

class PracticeDashboard extends React.Component {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
  };

  state = {
    loading: false,
    currentPage: 1,
    searchByName: "",
    searched: false,
    searchedPractice: [],
    searchedTotalRecords: 0,
  };

  componentWillMount() {
    this.setState({ loading: true });
    const options = {
      page: this.state.currentPage,
      api_type: "react",
    };
    this.props.fetchPractice({ params: options }).then((res) => {
      this.setState({ loading: false });
    });
  }

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  goToPage = (page) => {
    this.setLoading(true);
    this.setCurrentPage(page);

    const options = {
      page: page,
      api_type: "react",
    };
    this.props.fetchPractice({ params: options }).then((res) => {
      this.setLoading(false);
    });
  };

  showAdd = () => this.props.history.push(APP_ROUTES.ADD_PRACTICE);

  showManagePractice = (practiceId) =>
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_PRACTICE,
      state: {
        practiceId,
        activePage:
          this.props.activePage || this.props.location.state.activePage,
      },
    });

  deletePractice = (user) => {
    practiceService.deletePractice(user).then((response) => {
      this.goToPage(1);
    });
  };

  list = () => {
    const { practices = [] } = this.props;
    return practices.map((practice) => {
      return [
        `${practice.paytonpi ? practice.paytonpi : ""}`,
        practice.paytoname,
        practice.payregion,
        practice.id,
      ];
    });
  };

  searchPractice = () => {
    const { searchByName } = this.state;
    let options = {};

    if (searchByName) {
      options = { ...options, search: searchByName };
    }

    this.setState({
      searched: true,
      loading: true,
    });
    options = { ...options, api_type: "react" };
    practiceService.getPractices({ params: options }).then((response) => {
      this.setState({
        searchedTotalRecords: parseInt(response.records),
        searchedPractice: response.data.map((inc) => {
          return [
            `${inc.description ? inc.description : ""}`,
            inc.payor_code,
            inc.id,
          ];
        }),
      });
      this.setState({ loading: false });
    });
  };

  resetPracticeSearch = () => {
    this.setState({
      searchByName: "",
      searchedPractice: [],
      searched: false,
      searchedTotalRecords: 0,
    });
  };

  render() {
    const activePage =
      this.props.activePage || this.props.location.state.activePage;
    const { searched, searchedPractice, searchedTotalRecords } = this.state;
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={activePage} />
        </div>
        <TopNavbar />
        <div
          style={{
            minHeight: "755px",
          }}
          className="right-col"
        >
          <div className="page-title">
            <div className="title_left">
              <h3
                style={{
                  fontFamily:
                    "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "1.471",
                }}
              >
                Practice
              </h3>
            </div>
          </div>
          <div className="filter-footer-header">
            <button
              onClick={() => {
                this.showAdd(true);
              }}
              className="filter-button"
            >
              Add New Practice
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                paddingLeft: "10px",
              }}
              className="col-md-3 mrgn-btm10"
            >
              <label>Name</label>
              <input
                type="text"
                className="code-input"
                value={this.state.searchByName}
                onChange={(event) => {
                  this.setState({ searchByName: event.target.value }, () => {
                    if (this.state.searchByName.length >= 3) {
                      this.searchPractice();
                    } else if (this.state.searchByName.length === 0) {
                      this.resetPracticeSearch();
                    }
                  });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <button
                style={{
                  marginTop: "32px",
                  width: "100px",
                  height: "38px",
                }}
                onClick={this.resetPracticeSearch}
                className="filter-save-button"
              >
                Reset
              </button>
            </div>
          </div>
          <div className="row">
            <div className="activities-header">
              <PracticeListingTable
                isLoading={this.state.loading}
                currentPage={this.state.currentPage}
                goToPage={this.goToPage}
                edit={this.showManagePractice}
                delete={this.deletePractice}
                headColumn={PRACTICE_LISTING_COLUMNS}
                listing={
                  searched && searchedPractice.length > 0
                    ? searchedPractice
                    : this.list()
                }
                totalRecords={
                  searched && searchedTotalRecords > 0
                    ? searchedTotalRecords
                    : this.props.totalRecords
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    practices: state.practiceReducer.practices,
    totalRecords: state.practiceReducer.totalpractices,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPractice,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PracticeDashboard));
