import { combineReducers } from 'redux';
import authenticationReducer  from './authenticationReducer';
import activityReducer from "./activityReducer";
import dashboardReducer from "./dashboardReducer";
import patientReducer from "./patientReducer";
import notificationReducer from "./notificationReducer";
import organizationReducer from "./organizationReducer";
import surgeonReducer from "./surgeonReducer";
import procedureReducer from "./procedureReducer";
import assistantReducer from "./assistantReducer";
import facilityReducer from "./facilityReducer";
import userReducer from "./userReducer";
import claimsReducer from "./claimsReducer";
import regionReducer from "./regionReducer";
import caseLogReducer from "./caseLogReducer";
import billingReducer from "./billingReducer";
import insuranceReducer from "./insuranceReducer";
import practiceReducer from "./practiceReducer";
import batchReducer from "./batchReducer";
import accountReducer from "./accountReducer";
import invoicingReducer from "./invoicingReducer";
import logsReducer from './logsReducer';
import clientReducer from './clientReducer';

export default combineReducers({
    authenticationReducer,
    assistantReducer,
    activityReducer,
    dashboardReducer,
    patientReducer,
    organizationReducer,
    surgeonReducer,
    procedureReducer,
    facilityReducer,
    clientReducer,
    invoicingReducer,
    logsReducer,
    userReducer,
    claimsReducer,
    caseLogReducer,
    billingReducer,
    insuranceReducer,
    practiceReducer,
    regionReducer,
    batchReducer,
    notificationReducer,
    accountReducer,
});