import axios from "axios";
import { LOCAL_STORAGE_KEYS } from "../constants/common";

export const apiClient = (cancelToken) => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || null;
    const tenantid = localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_ID) || null;
    const tenantfound = localStorage.getItem(LOCAL_STORAGE_KEYS.TENANT_FOUND) || null;
    const baseURLWithoutTenant = process.env.REACT_APP_API_URL.replace('{tenant}', '');
    const baseURL = process.env.REACT_APP_API_URL;
    const tenantURL = baseURL.replace('{tenant}', ((tenantid && tenantfound && tenantfound == "1") ? tenantid + '/' : ''));

    const tenatExcludeList = ['client/check-client'];
    const defaultOptions = {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    };

    return {
        get: (url, options = {}) => {
            let tURL = '';
            if (tenatExcludeList.findIndex(temp => temp === url) >= 0)
                tURL = baseURLWithoutTenant + url;
            else
                tURL = tenantURL + url;

            return axios.get(`${tURL}`, { ...defaultOptions, ...options, cancelToken: cancelToken })
        },
        post: (url, data, options = {}) => {
            let tURL = '';
            if (tenatExcludeList.findIndex(temp => temp === url) >= 0)
                tURL = baseURLWithoutTenant + url;
            else
                tURL = tenantURL + url;
            return axios.post(`${tURL}`, data, { ...defaultOptions, ...options })
        },
        put: (url, data, options = {}) => {
            let tURL = '';
            if (tenatExcludeList.findIndex(temp => temp === url) >= 0)
                tURL = baseURLWithoutTenant + url;
            else
                tURL = tenantURL + url;
            return axios.put(`${tURL}`, data, { ...defaultOptions, ...options })
        },
        delete: (url, options = {}) => {
            let tURL = '';
            if (tenatExcludeList.findIndex(temp => temp === url) >= 0)
                tURL = baseURLWithoutTenant + url;
            else
                tURL = tenantURL + url;
            return axios.delete(`${tURL}`, { ...defaultOptions, ...options })
        }
    };

}