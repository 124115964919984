import React from "react";
import PropTypes from "prop-types";
import { RECORDS_PER_PAGE } from "../../constants/common";
import Loader from "./Loader";

import loaderImage from "../../../assets/images/CoureMax.gif";
import LoadingOverlay from "react-loading-overlay";

export default class LogsListingTable extends React.Component {
  state = {
    fee_sum: 0,
    row_Color: "",
    row_Color_Text: "",
  };

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    goToPage: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    headColumn: PropTypes.array.isRequired,
    listing: PropTypes.array.isRequired,
    totalRecords: PropTypes.number.isRequired,
    allChecked: PropTypes.bool.isRequired,
    addAllCheckedItem: PropTypes.func.isRequired,
    removeAllCheckedItem: PropTypes.func.isRequired,
  };
  renderHeadColumn = () => {
    const { headColumn } = this.props;

    const header = headColumn.map((column, index) => {
      if (index === 1 || index === 2) {
        return (
          <td
            style={{
              textAlign: "center",
            }}
            className="listing-table-head-column"
          >
            {column}
          </td>
        );
      } else {
        return <th className="listing-table-head-column">{column}</th>;
      }
    });
    return header;
  };

  renderPagination = () => {
    const { totalRecords, currentPage } = this.props;

    const totalPages = Math.ceil(totalRecords / RECORDS_PER_PAGE);

    const visiblePaginationPages = 6;

    const pageIndexes = [];

    const visiblePages = visiblePaginationPages / 2;
    if (totalPages > visiblePaginationPages + 2) {
      const firstPage = Math.min(
        currentPage,
        Math.max(totalPages - visiblePages * 2, 0)
      );
      for (let i = firstPage; i < firstPage + visiblePages; i++) {
        pageIndexes.push(i);
      }

      if (currentPage + visiblePages < totalPages - visiblePages) {
        pageIndexes.push(-1);
      }

      for (let i = totalPages - visiblePages; i < totalPages; i++) {
        pageIndexes.push(i);
      }
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pageIndexes.push(i);
      }
    }

    const pageLinks = pageIndexes.map((i, index) => {
      const button = (
        <button
          disabled={currentPage === i}
          key={`pagination-button-${index}`}
          onClick={() => {
            return this.props.goToPage(i);
          }}
          className="listing-pagination-button"
        >
          {i}
        </button>
      );

      const ellipsis = (
        <div
          className="listing-pagination-button"
          key={`pagination-ellipsis-${index}`}
        >
          ...
        </div>
      );
      return -1 === i ? ellipsis : button;
    });
    return (
      <div className="listing-pagination">
        <label style={{ marginTop: "5px", marginRight: "5px" }}>
          Page Size:
        </label>
        <select
          className="form-control"
          style={{ width: "80px" }}
          onChange={(event) => this.props.setPageSize(event.target.value)}
          value={this.props.pageSize}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="60">60</option>
          <option value="70">70</option>
          <option value="80">80</option>
          <option value="90">90</option>
          <option value="100">100</option>
        </select>
        <div className="inner">
          <button
            onClick={() => {
              return this.props.goToPage(1);
            }}
            disabled={1 === currentPage}
            className="listing-pagination-button"
          >
            &lt;&lt; First
          </button>
          <button
            onClick={() => {
              return this.props.goToPage(currentPage - 1);
            }}
            disabled={1 === currentPage}
            className="listing-pagination-button"
          >
            &lt; Previous
          </button>
          {pageLinks}
          <button
            onClick={() => {
              return this.props.goToPage(currentPage + 1);
            }}
            disabled={currentPage === totalPages - 1}
            className="listing-pagination-button"
          >
            Next &gt;
          </button>
          <button
            onClick={() => {
              return this.props.goToPage(totalPages - 1);
            }}
            disabled={currentPage === totalPages - 1}
            className="listing-pagination-button"
          >
            Last &gt;&gt;
          </button>
        </div>
      </div>
    );
  };

  renderList = () => {
    const { listing = [] } = this.props;
    //let sum = 0;
    /*const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );*/
    return (
      listing.length > 0 &&
      listing.map((listRow) => {
        const item = listRow.map((listItem, index) => {                 //eslint-disable-line
          if (index === 0) {
            return <td className="listing-table-column">{listItem}</td>;
          } else if (index < listRow.length - 1) {
            if (index === 2 || index === 3) {
              return (
                <td
                  style={{
                    textAlign: "left",
                    // backgroundColor: "black",
                    // color: "white" + "!important",
                  }}
                  className="listing-table-column"
                >
                  {listItem}
                </td>
              );
            }

            return (
              <td
                className="listing-table-column"
                style={{
                  backgroundColor: this.state.row_Color,
                  color: this.state.row_Color_Text + "!important",
                  textAlign: "center",
                }}
              >
                <p data-tip={listItem}>
                  <span id={`TooltipExample`}>
                    {index === 0 || (listItem && listItem.length < 12)
                      ? listItem
                      : listItem && listItem}
                  </span>
                </p>
              </td>
            );
          }
        });

        return <tr className="listing-table-rows">{item}</tr>;
      })
    );
  };

  render() {
    return (
      <div className="x_panel">
        <div className="x_content">
          <div className="table-responsive">
            <LoadingOverlay
              active={this.state.loading}
              spinner={
                <img
                  style={{
                    width: "200px",
                  }}
                  src={loaderImage}
                  alt="loading..."
                />
              }
            >
              <table className="listing-table">
                <thead className="listing-table-head">
                  <tr>{this.renderHeadColumn()}</tr>
                </thead>
                {this.props.isLoading && <Loader width="578%" />}
                {!this.props.isLoading && (
                  <tbody>
                    {this.renderList()}
                    {/* <tr
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      fontSize: 30,
                      textAlign: "center",
                    }}
                  >
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Total</td>
                    <td>${this.state.fee_sum}</td>
                  </tr> */}
                  </tbody>
                )}
              </table>
            </LoadingOverlay>
          </div>
          {this.props.totalRecords > this.props.pageSize &&
          this.props.listing.length > 0 ? (
            this.renderPagination()
          ) : (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                paddingTop: "2px",
              }}
            >
              {!this.props.isLoading && this.props.listing.length === 0 && (
                <span className="noRecord">No Records Found</span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
