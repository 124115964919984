import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { createBill, updateBill } from "../../actions/billing";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import DatePicker from "react-datepicker";
import { AsyncPaginate } from "react-select-async-paginate";
import { NOTIFICATION_TYPES, RECORDS_PER_PAGE } from "../../constants/common";
import { practiceService } from "../../services/practices";
import { APP_ROUTES } from "../../constants/common";

import ServiceLine from "../atom/ServiceLine";
import { billingService } from "../../services/billing";
import { createNotification } from "../../utils/notificationManager";
import { activityService } from "../../services/activities";

const practices = [
  "BLUE START SURGICAL ASSISTANT LLC",
  "UNIVERSAL SURGICAL LLC",
  "XCITE SURGICAL LLC",
];

class ManageBilling extends React.Component {
  static propTypes = {
    createBill: PropTypes.func.isRequired,
    updateBill: PropTypes.func.isRequired,
  };

  state = {
    serviceDate: "",
    serviceDateEnd: "",
    facility: {},
    assistant: {},
    surgeon: {},
    pos: "21",
    billPayTo: "",
    addBillDiagnosis1: "",
    addBillDiagnosis2: "",
    addBillDiagnosis3: "",
    addBillDiagnosis4: "",
    addBillDiagnosis5: "",
    addBillDiagnosis6: "",
    addBillDiagnosis7: "",
    addBillDiagnosis8: "",
    addBillDiagnosis9: "",
    addBillDiagnosis10: "",
    addBillDiagnosis11: "",
    addBillDiagnosis12: "",
    totalBill: 0,
    serviceLines: [],
    loadingCPT: false,
  };

  componentDidMount() {
    let eventId;
    let billId;
    if (this.props.location.state) {
      eventId = this.props.location.state.eventId;
      billId = this.props.location.state.billId;
    } else {
      eventId = "";
      billId = "";
    }
    if (this.props.eventId) {
      eventId = this.props.eventId;
      billId = this.props.billId;
    }
    if (eventId) {
      activityService.getActivityDetail(eventId).then((response) => {
        this.setState({
          serviceDate: new Date(response.data.event_start_date),
          serviceDateEnd: response.data.event_end_date
            ? new Date(response.data.event_end_date)
            : "",
        });
      });
    }
    if (eventId) {
      billingService.getEventBill(eventId).then((response) => {
        this.setState({
          facility: response.data.facility
            ? response.data.facility.facility_name
            : "",
          assistant: response.data.assistant
            ? `${response.data.assistant.name} ${response.data.assistant.last_name}`
            : "",
          surgeon: response.data.surgeon
            ? `${response.data.surgeon.first_name} ${response.data.surgeon.last_name}`
            : "",
        });
        if (billId) {
          this.setState(
            {
              serviceDate: response.data.bill
                ? new Date(response.data.bill.service_date)
                : "",
              serviceDateEnd: response.data.bill
                ? new Date(response.data.bill.service_date_end)
                : "",
              pos: response.data.bill ? response.data.bill.pos : "",
              billPayTo: {
                label: response.data.bill
                  ? practices[response.data.bill.bill_to - 1]
                  : "",
                value: response.data.bill ? response.data.bill.bill_to : "",
              },
              addBillDiagnosis1: response.data.bill
                ? response.data.bill.add_bill_diagnosis1
                : "",
              addBillDiagnosis2: response.data.bill
                ? response.data.bill.add_bill_diagnosis2
                : "",
              addBillDiagnosis3: response.data.bill
                ? response.data.bill.add_bill_diagnosis3
                : "",
              addBillDiagnosis4: response.data.bill
                ? response.data.bill.add_bill_diagnosis4
                : "",
              addBillDiagnosis5: response.data.bill
                ? response.data.bill.add_bill_diagnosis5
                : "",
              addBillDiagnosis6: response.data.bill
                ? response.data.bill.add_bill_diagnosis6
                : "",
              addBillDiagnosis7: response.data.bill
                ? response.data.bill.add_bill_diagnosis7
                : "",
              addBillDiagnosis8: response.data.bill
                ? response.data.bill.add_bill_diagnosis8
                : "",
              addBillDiagnosis9: response.data.bill
                ? response.data.bill.add_bill_diagnosis9
                : "",
              addBillDiagnosis10: response.data.bill
                ? response.data.bill.add_bill_diagnosis10
                : "",
              addBillDiagnosis11: response.data.bill
                ? response.data.bill.add_bill_diagnosis11
                : "",
              addBillDiagnosis12: response.data.bill
                ? response.data.bill.add_bill_diagnosis12
                : "",
              totalBill: response.data.bill ? response.data.bill.total_bill : 0,
              serviceLines: response.data.bill
                ? response.data.bill.service_lines
                : [],
            },
            () => this.calculateBill()
          );
        } else {
          this.addServiceLine();
        }
      });
    }
  }

  loadPractices = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }
    return practiceService
      .getPractices({ params: options })
      .then((response) => {
        const options = response.data.map((practice) => ({
          value: practice.id,
          label: practice.paytoname,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  addServiceLine = () => {
    const serviceLine = {
      line_number: this.state.serviceLines.length + 1,
      line_start_date: this.state.serviceDate,
      line_end_date: this.state.serviceDateEnd,
      hcpcs_code: "",
      modifier1: "",
      modifier2: "",
      modifier3: "",
      modifier4: "",
      units: "",
      line_total: 0,
      line_discount: "",
      code_description: "",
      points_to_diagnosis: "",
      revenue_code: "",
    };
    this.setState(
      (prevState) => ({
        serviceLines: [...prevState.serviceLines, serviceLine],
      }),
      () => this.calculateBill()
    );
  };

  renderServiceLines = () => {
    const { serviceLines } = this.state;
    return serviceLines.map((serviceLine) => (
      <ServiceLine
        lineNumber={serviceLine.line_number}
        serviceDate={new Date(serviceLine.line_start_date)}
        serviceDateEnd={new Date(serviceLine.line_end_date)}
        cpt={serviceLine.hcpcs_code}
        mod1={serviceLine.modifier1}
        mod2={serviceLine.modifier2}
        mod3={serviceLine.modifier3}
        mod4={serviceLine.modifier4}
        units={serviceLine.units}
        total={serviceLine.line_total}
        discount={serviceLine.line_discount}
        cptDesc={serviceLine.code_description}
        pod={serviceLine.points_to_diagnosis}
        revenueCode={serviceLine.revenue_code}
        removeServiceLine={this.removeServiceLine}
        setServiceLineAttribute={this.setServiceLineAttribute}
      />
    ));
  };

  setServiceLineAttribute = (lineNumber, key, value) => {
    const { serviceLines } = this.state;

    const updatedServiceLines = serviceLines.map((serviceLine) => {
      if (serviceLine.line_number === lineNumber) {
        serviceLine[key] = value;
        if (key === "hcpcs_code" && value) {
          this.setState({ loadingCPT: true });
          billingService
            .getCPTFEE(value)
            .then((response) => {
              serviceLine["line_total"] = parseInt(
                response.data.fee_amount ? response.data.fee_amount : 0
              );
              this.setState({ loadingCPT: false }, () => this.calculateBill());
            })
            .catch((error) => {
              this.setState({ loadingCPT: false }, () => this.calculateBill());
            });
        }
      }
      return serviceLine;
    });

    this.setState({ serviceLines: updatedServiceLines }, () =>
      this.calculateBill()
    );
  };

  removeServiceLine = (lineNumber) => {
    const { serviceLines } = this.state;
    const filteredServiceLines = serviceLines.filter(
      (serviceLine) => serviceLine.line_number !== lineNumber
    );
    this.setState({ serviceLines: filteredServiceLines }, () =>
      this.calculateBill()
    );
  };

  calculateBill = () => {
    const { serviceLines } = this.state;
    let bill = 0;
    if (serviceLines != null) {
      serviceLines.map((serviceLine) => {                 //eslint-disable-line
        bill = bill + parseInt(serviceLine.line_total);
      });
    }

    this.setState({ totalBill: bill });
  };

  submit = () => {
    let eventId;
    let billId;
    if (this.props.location.state) {
      eventId = this.props.location.state.eventId;
      billId = this.props.location.state.billId;
    } else {
      eventId = "";
      billId = "";
    }
    if (this.props.eventId) {
      eventId = this.props.eventId;
      billId = this.props.billId;
    }
    const { createBill, updateBill } = this.props;

    const {
      serviceDate,
      serviceDateEnd,
      pos,
      billPayTo,
      addBillDiagnosis1,
      addBillDiagnosis2,
      addBillDiagnosis3,
      addBillDiagnosis4,
      addBillDiagnosis5,
      addBillDiagnosis6,
      addBillDiagnosis7,
      addBillDiagnosis8,
      addBillDiagnosis9,
      addBillDiagnosis10,
      addBillDiagnosis11,
      addBillDiagnosis12,
      totalBill,
      serviceLines,
    } = this.state;

    const request = {
      event_id: eventId,
      service_date: serviceDate,
      service_date_end: serviceDateEnd,
      pos_select: pos,
      bill_pay_to_select: billPayTo.value,
      add_bill_diagnosis1: addBillDiagnosis1,
      add_bill_diagnosis2: addBillDiagnosis2,
      add_bill_diagnosis3: addBillDiagnosis3,
      add_bill_diagnosis4: addBillDiagnosis4,
      add_bill_diagnosis5: addBillDiagnosis5,
      add_bill_diagnosis6: addBillDiagnosis6,
      add_bill_diagnosis7: addBillDiagnosis7,
      add_bill_diagnosis8: addBillDiagnosis8,
      add_bill_diagnosis9: addBillDiagnosis9,
      add_bill_diagnosis10: addBillDiagnosis10,
      add_bill_diagnosis11: addBillDiagnosis11,
      add_bill_diagnosis12: addBillDiagnosis12,
      total_bill: totalBill,
      service_lines: serviceLines,
    };

    if (billId) {
      updateBill({ ...request, id: billId })
        .then((res) => {
          if (res.response.success) {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            const screen = this.props.location.state
              ? this.props.location.state.screen
              : "";
            if (screen) {
              this.props.history.push(screen);
            } else {
              //   this.props.history.push(APP_ROUTES.CLAIMS_AND_COLLECTION);
            }
          } else {
            createNotification(
              NOTIFICATION_TYPES.ERROR,
              `Unable to update bill ${res.response.message}`
            );
          }
        })
        .catch((err) => {
          createNotification(
            NOTIFICATION_TYPES.ERROR,
            `Unable to update bill ${err.error.response.data.message}`
          );
        });
    } else {
      createBill(request)
        .then((res) => {
          if (res.response.success) {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            const screen = this.props.location.state
              ? this.props.location.state.screen
              : "";
            if (screen) {
              this.props.history.push(screen);
            } else {
              //   this.props.history.push(APP_ROUTES.CLAIMS_AND_COLLECTION);
            }
          } else {
            createNotification(
              NOTIFICATION_TYPES.ERROR,
              `Unable to create bill ${res.response.message} ${JSON.stringify(
                res.response.data
              )}`
            );
          }
        })
        .catch((err) => {
          createNotification(
            NOTIFICATION_TYPES.ERROR,
            `Unable to create bill ${err.error.response.data.message}`
          );
        });
    }
  };

  cancel = () => {
    const screen = this.props.location.state
      ? this.props.location.state.screen
      : "";
    if (screen) {
      this.props.history.push(screen);
    } else {
      this.props.history.push(APP_ROUTES.CLAIMS_AND_COLLECTION);
    }
  };

  render() {
    const billId = this.props.location.state
      ? this.props.location.state.billId
      : "";
    const activePage = this.props.location.state
      ? this.props.location.state.activePage
      : "";
    return (
      <div className="main_container">
        {this.props.PopupRequest === undefined && (
          <>
            <div
              className="col-md-3 custom-sidebar-menu left_col"
              style={{
                minHeight: "100vh",
              }}
            >
              <Sidebar activePage={activePage} />
            </div>
            <TopNavbar />
          </>
        )}
        <div
          className={
            this.props.PopupRequest === 1 ? "" : "manage-activity-right-col"
          }
        >
          <div className="activity-row">
            <div className="page-title">
              {billId && <h2 className="heading-custom">Edit Bill</h2>}
              {!billId && <h2 className="heading-custom">Add Bill</h2>}
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Service Date Start</label>
              <br />
              <DatePicker
                isClearable
                selected={this.state.serviceDate}
                onChange={(date) => this.setState({ serviceDate: date })}
                className="date-picker"
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Service Date End</label>
              <br />
              <DatePicker
                isClearable
                selected={this.state.serviceDateEnd}
                onChange={(date) => this.setState({ serviceDateEnd: date })}
                className="date-picker"
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Facility</label>
              <input
                disabled
                type="text"
                className="code-input"
                value={this.state.facility}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Surgical Assistant</label>
              <input
                disabled
                type="text"
                className="code-input"
                value={this.state.assistant}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Referring Provider</label>
              <input
                disabled
                type="text"
                className="code-input"
                value={this.state.surgeon}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Place of Service</label>
              <select
                className="filter-input"
                value={this.state.pos}
                onChange={(event) => this.setState({ pos: event.target.value })}
              >
                <option value="0">Unknown</option>
                <option value="11">11 Office</option>
                <option value="21">Inpatient Hospital</option>
                <option value="22">Outpatient Hospital</option>
                <option value="23">Emergency Room - Hospital</option>
                <option value="24">Ambulatory Surgical Center</option>
              </select>
            </div>
            <div className="col-md-3 mrgn-btm10">
              <label>Pay To</label>
              <AsyncPaginate
                placeholder="-- Select Practice --"
                value={this.state.billPayTo}
                loadOptions={this.loadPractices}
                onChange={(value) => this.setState({ billPayTo: value })}
              />
            </div>
            <div className="page-title">
              <h2 className="heading-custom">Diagnosis Codes</h2>
            </div>
            <div className="col-md-2 mrgn-btm10">
              <input
                placeholder="Diagnosis 1"
                onChange={(event) =>
                  this.setState({ addBillDiagnosis1: event.target.value })
                }
                type="text"
                className="code-input"
                value={this.state.addBillDiagnosis1}
              />
            </div>
            <div className="col-md-2 mrgn-btm10">
              <input
                placeholder="Diagnosis 2"
                onChange={(event) =>
                  this.setState({ addBillDiagnosis2: event.target.value })
                }
                type="text"
                className="code-input"
                value={this.state.addBillDiagnosis2}
              />
            </div>
            <div className="col-md-2 mrgn-btm10">
              <input
                placeholder="Diagnosis 3"
                onChange={(event) =>
                  this.setState({ addBillDiagnosis3: event.target.value })
                }
                type="text"
                className="code-input"
                value={this.state.addBillDiagnosis3}
              />
            </div>
            <div className="col-md-2 mrgn-btm10">
              <input
                placeholder="Diagnosis 4"
                onChange={(event) =>
                  this.setState({ addBillDiagnosis4: event.target.value })
                }
                type="text"
                className="code-input"
                value={this.state.addBillDiagnosis4}
              />
            </div>
            <div className="col-md-2 mrgn-btm10">
              <input
                placeholder="Diagnosis 5"
                onChange={(event) =>
                  this.setState({ addBillDiagnosis5: event.target.value })
                }
                type="text"
                className="code-input"
                value={this.state.addBillDiagnosis5}
              />
            </div>
            <div className="col-md-2 mrgn-btm10">
              <input
                placeholder="Diagnosis 6"
                onChange={(event) =>
                  this.setState({ addBillDiagnosis6: event.target.value })
                }
                type="text"
                className="code-input"
                value={this.state.addBillDiagnosis6}
              />
            </div>
            <div className="col-md-2 mrgn-btm10">
              <input
                placeholder="Diagnosis 7"
                onChange={(event) =>
                  this.setState({ addBillDiagnosis7: event.target.value })
                }
                type="text"
                className="code-input"
                value={this.state.addBillDiagnosis7}
              />
            </div>
            <div className="col-md-2 mrgn-btm10">
              <input
                placeholder="Diagnosis 8"
                onChange={(event) =>
                  this.setState({ addBillDiagnosis8: event.target.value })
                }
                type="text"
                className="code-input"
                value={this.state.addBillDiagnosis8}
              />
            </div>
            <div className="col-md-2 mrgn-btm10">
              <input
                placeholder="Diagnosis 9"
                onChange={(event) =>
                  this.setState({ addBillDiagnosis9: event.target.value })
                }
                type="text"
                className="code-input"
                value={this.state.addBillDiagnosis9}
              />
            </div>
            <div className="col-md-2 mrgn-btm10">
              <input
                placeholder="Diagnosis 10"
                onChange={(event) =>
                  this.setState({ addBillDiagnosis10: event.target.value })
                }
                type="text"
                className="code-input"
                value={this.state.addBillDiagnosis10}
              />
            </div>
            <div className="col-md-2 mrgn-btm10">
              <input
                placeholder="Diagnosis 11"
                onChange={(event) =>
                  this.setState({ addBillDiagnosis11: event.target.value })
                }
                type="text"
                className="code-input"
                value={this.state.addBillDiagnosis11}
              />
            </div>
            <div className="col-md-2 mrgn-btm10">
              <input
                placeholder="Diagnosis 12"
                onChange={(event) =>
                  this.setState({ addBillDiagnosis12: event.target.value })
                }
                type="text"
                className="code-input"
                value={this.state.addBillDiagnosis12}
              />
            </div>
            <div className="page-title">
              <h2 className="heading-custom">Service Lines</h2>
            </div>
            <div className="col-md-12 mrgn-btm10">
              <button
                onClick={this.addServiceLine}
                className="filter-save-button"
              >
                Add Service Line
              </button>
            </div>
            {this.state.serviceLines && this.renderServiceLines()}
            <div className="page-title">
              <h2 className="heading-custom">Total Bill</h2>
            </div>
            <div className="col-md-12 mrgn-btm10">
              <input
                disabled
                type="text"
                className="code-input"
                value={this.state.totalBill}
              />
            </div>
            <div className="filter-footer">
              <button onClick={this.submit} className="filter-save-button">
                Submit
              </button>
              {this.props.PopupRequest === undefined && (
                <button onClick={this.cancel} className="filter-cancel-button">
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createBill,
      updateBill,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageBilling));
