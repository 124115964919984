import React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {fetchOrganization} from "../../actions/organization";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {APP_ROUTES, LOCAL_STORAGE_KEYS, ORGANIZATION_LISTING_COLUMNS} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import OrganizationListingTable from "../atom/OrganizationListingTable";
import {organizationService} from "../../services/organizations";

class OrganizationDashboard extends React.Component {
    static propTypes = {
        totalRecords: PropTypes.number.isRequired,
    };

    state = {
        loading: false,
        currentPage: 1,
    };

    componentDidMount() {
        this.setState({loading: true});
        const options = {
            page: this.state.currentPage,
        };
        this.props.fetchOrganization({ params: options })
            .then(res => {
                this.setState({loading: false});
            });
    }

    list = () => {
        const { organizations = [] } = this.props;

        return organizations.map(organization => {
            return [
                organization.org_name,
                organization.org_email,
                organization.org_phone,
                organization.org_address,
                organization.is_active == '1' ? 'YES' : 'NO',       //eslint-disable-line
                organization.id,
            ];
        });
    };

    setLoading = (flag) => {
        this.setState({ loading: flag });
    };

    goToPage = (page) => {
        this.setLoading(true);
        const options = {
            page: page < 0 ? 1 : page,
        };
        this.setCurrentPage(options.page);
        this.props.fetchOrganization({ params: options })
            .then(res => {
                this.setLoading(false);
            });
    };

    showManageOrganization = organizationId => this.props.history.push({
        pathname: APP_ROUTES.EDIT_ORGANIZATION,
        state: {
            activePage: this.props.activePage || this.props.location.state.activePage,
            organizationId,
        }
    });

    deleteOrganization = organization => {
        organizationService
            .deleteOrganization(organization)
            .then(response => {
                this.goToPage(1);
            });
    };

    showAdd = () => this.props.history.push({
        pathname: APP_ROUTES.ADD_ORGANIZATION,
        state: {
            activePage: this.props.activePage || this.props.location.state.activePage,
        }
    });

    render() {
        const activePage = this.props.activePage || this.props.location.state.activePage;
        const loggedInUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER));
        return (
            <div className="main_container">
                <div className="col-md-3 custom-sidebar-menu left_col">
                    <Sidebar activePage={activePage} />
                </div>
                <TopNavbar />
                <div
                    style={{
                        minHeight: '760px'
                    }}
                    className="right-col">
                    <div className="page-title">
                        <div className="title_left">
                            <h3 style={{
                                fontFamily: 'Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif',
                                fontSize: '24px',
                                fontWeight: '400',
                                lineHeight: '1.471',
                            }}>Organizations</h3>
                        </div>
                    </div>
                    { loggedInUser.isAddAccess && (
                        <div className="filter-footer-header">
                            <button
                                onClick={this.showAdd}
                                className="patients-filter-button">
                                Add New Organization
                            </button>
                        </div>
                    )}
                    <div className="row">
                        <div className="activities-header">
                            <OrganizationListingTable
                                isLoading={this.state.loading}
                                currentPage={this.state.currentPage}
                                goToPage={this.goToPage}
                                edit={this.showManageOrganization}
                                delete={this.deleteOrganization}
                                headColumn={ORGANIZATION_LISTING_COLUMNS}
                                listing={this.list()}
                                totalRecords={this.props.totalRecords}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        organizations: state.organizationReducer.organizations,
        totalRecords: state.organizationReducer.totalOrganizations,
    }
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            fetchOrganization,
        },
        dispatch,
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(OrganizationDashboard));