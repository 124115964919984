import React from "react";
import {
  LOGS_LISTING_COLUMNS,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { bindActionCreators } from "redux";
import { fetchLogs } from "../../actions/logs";
import LogsListingTable from "../atom/LogsListingTable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import LogsFilters from "../organisms/LogsFilters";
import { formatDate } from "../../utils/date";

class LogsDashboard extends React.Component {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
    fetchLogs: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    allChecked: false,
    currentPage: 1,
    searchByName: "",
    fromDate: "",
    toDate: "",
    assistant: {
      value: "",
      label: "Select Assistant",
    },
    patient: {
      value: "",
      label: "Select Patient",
    },

    createdBy: {
      value: "",
      label: "Select Modified By",
    },
    flipAssistant: false,
    pageSize: "10",
  };

  componentDidMount() {
    this.setState({ loading: true });
    /*const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };*/
  }
  componentWillMount() {
    this.setState({ loading: true });
    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };
    this.props.fetchLogs({ params: options }).then((res) => {
      this.setState({ loading: false });
    });
  }
  setPageSize = (value) => {
    this.setState({ pageSize: value }, () => {
      this.goToPage(1);
    });
  };

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  goToPage = (page) => {
    this.setLoading(true);
    this.setCurrentPage(page);

    const options = {
      page: page < 0 ? 1 : page,
      page_size: this.state.pageSize,
    };
    this.props.fetchLogs({ params: options }).then((res) => {
      this.setLoading(false);
    });
  };

  list = () => {
    let { logs = [] } = this.props;

    return logs.map((log) => {
      return [
        log.id,
        log.subject ? log.subject : 0,
        log ? log.name : "",
        log.module,
        log.url,
        log.method,
        log.ip,
        log.agent,
        log.user.name + " " + log.user.last_name,
        log.created_at,
        log.created_at,
      ];
    });
  };

  setFromDate = (date) => {
    this.setState({ fromDate: date });
  };

  setToDate = (date) => {
    this.setState({ toDate: date });
  };

  setAssistant = (value) => {
    this.setState({ assistant: value });
  };

  setSearchInvoice = (value) => {
    this.setState({ searchByName: value });
  };

  setPatient = (value) => {
    this.setState({ patient: value });
  };

  setCreatedBy = (value) => {
    this.setState({ createdBy: value });
  };

  setFlipAssistant = (value) => {
    this.setState({ flipAssistant: value });
  };
  setFromDate = (date) => {
    this.setState({ fromDate: date });
  };

  addFilters = (options) => {
    const { fromDate, toDate, assistant, patient, createdBy, searchByName } =
      this.state;
    if (fromDate) {
      options = { ...options, from_date: formatDate(fromDate) };
    }
    if (toDate) {
      options = { ...options, to_date: formatDate(toDate) };
    }
    if (assistant.value) {
      options = { ...options, assistant_id: assistant.value };
    }
    if (patient.value) {
      options = { ...options, patient_id: patient.value };
    }
    if (createdBy.value) {
      options = { ...options, created_by: createdBy.value };
    }
    if (searchByName) {
      options = { ...options, invoicenumber: searchByName };
    }
    return options;
  };

  search = () => {
    this.setState({ loading: true });
    this.setCurrentPage(1);
    const options = {
      page: this.state.currentPage,
      page_size: this.state.pageSize,
    };

    this.props.fetchLogs({ params: this.addFilters(options) }).then((res) => {
      this.setState({ loading: false });
    });
  };
  render() {
    const activePage =
      this.props.activePage || this.props.location.state.activePage;
    /*const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );*/

    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={activePage} />
        </div>
        <TopNavbar />

        <div
          style={{
            minHeight: "760px",
          }}
          className="right-col"
        >
          <LogsFilters
            goToPage={this.goToPage}
            exportEnabled={this.exportEnabled}
            search={this.search}
            setFromDate={this.setFromDate}
            setToDate={this.setToDate}
            setAssistant={this.setAssistant}
            setSearchInvoice={this.setSearchInvoice}
            setPatient={this.setPatient}
            setFlipAssistant={this.setFlipAssistant}
            setCreatedBy={this.setCreatedBy}
            fromDate={this.state.fromDate}
            toDate={this.state.toDate}
            assistant={this.state.assistant}
            patient={this.state.patient}
            createdBy={this.state.createdBy}
            flipAssistant={this.state.flipAssistant}
          />

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          ></div>
          <div className="filter-footer"></div>

          <div className="row">
            <div className="activities-header">
              <LogsListingTable
                isLoading={this.state.loading}
                currentPage={this.state.currentPage}
                goToPage={this.goToPage}
                headColumn={LOGS_LISTING_COLUMNS}
                listing={this.list()}
                totalRecords={this.props.totalRecords}
                setPageSize={this.setPageSize}
                pageSize={this.state.pageSize}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logs: state.logsReducer.logs,
    totalRecords: state.logsReducer.totalLogs,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchLogs,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LogsDashboard));
