import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { updateUser } from "../../actions/profile";
import {
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";
import dummyprofileimage from "../../../assets/images/img.jpg";

const REQUIRED_FIELDS = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  EMAIL: "email",
};
class Profile extends React.Component {
  state = {
    errors: [],
    firstName: "",
    lastName: "",
    email: "",
    file: null,
    imagePreview: dummyprofileimage,
  };
  componentDidMount() {
    const userId = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );

    if (userId) {
      // userProfileService.getUserProfile(userId).then((response) => {

      this.setState({
        firstName: userId.name,
        lastName: userId.last_name,
        email: userId.email,
      });
      // });
      if(userId.image !== "assets/avatar/" ){
        this.setState({
          imagePreview: process.env.REACT_APP_API_URL.replace("api/",'') + userId.image,
        });
      }
    }
  }

  hasErrors = (field) => {
    return this.state.errors.includes(field);
  };
  resetErrors = (field) => {
    this.setState({
      errors: this.state.errors.filter((fieldName) => fieldName !== field),
    });
  };

  validateActivity = () => {
    const { firstName, lastName, errors } = this.state;

    let isValid = true;

    if (firstName.length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.FIRST_NAME] });
      isValid = false;
    } else if (lastName.length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.LAST_NAME] });
      isValid = false;
    }

    if (!isValid) {
      createNotification(
        NOTIFICATION_TYPES.ERROR,
        "Please select required values"
      );
    }

    return isValid;
  };

  submitUser = () => {
    if (!this.validateActivity()) {
      return;
    }
    const { updateUser } = this.props;
    const { firstName, lastName, email, file } = this.state;

    // const request = {
    //   first_name: firstName,
    //   last_name: lastName,
    //   email: email,
    //   image: file,
    // };

    const userId = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );

    const formData = new FormData();
    formData.append("file", file);
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", email);
    formData.append("id", userId["id"]);
    if (userId) {
      // const payload = { ...request, id: userId, formData };
      updateUser(formData)
        .then((res) => {

          if (res.response.success) {
            createNotification(
              NOTIFICATION_TYPES.SUCCESS,
              res.response.message
            );
            localStorage.setItem(
              LOCAL_STORAGE_KEYS.LOGGED_IN_USER,
              JSON.stringify({
                ...userId,
                name: this.state.firstName,
                last_name: this.state.lastName,
                image: res.response.data.image,
              })
            );
            this.props.history.push(APP_ROUTES.PROFILE);
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
            //this.props.history.push(APP_ROUTES.USER_DASHBOARD);
          }
        })
        .catch((err) => {
          createNotification(
            NOTIFICATION_TYPES.ERROR,
            `Unable to update user ${err.error.response.data.message}`
          );
        });
    }
  };

  onProfileImageChangeHandler = (event) => {
    this.setState({
      imagePreview: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    });
  };

  render() {
    const userId = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );

    const activePage =
      this.props.activePage || this.props.location.state.activePage;
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={activePage} />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {<h2 className="heading-custom">Edit User</h2>}
            </div>
            <div class="container">
              <div className="row">
                <div className="col-md-4 mrgn-btm10">
                  <img
                    style={{
                      width: "200px",
                      height: "200px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      border: "4px solid green ",
                    }}
                    src={this.state.imagePreview}
                    alt=""
                    accept="image/*"
                  />
                  <input
                    type="file"
                    name="file"
                    className="file-upload"
                    onChange={this.onProfileImageChangeHandler}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mrgn-btm10">
                  <label>
                    First Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      this.hasErrors(REQUIRED_FIELDS.FIRST_NAME)
                        ? "error code-input"
                        : "code-input"
                    }
                    value={this.state.firstName}
                    onChange={(event) => {
                      this.resetErrors(REQUIRED_FIELDS.FIRST_NAME);
                      this.setState({ firstName: event.target.value });
                    }}
                  />
                </div>

                <div className="col-md-4 mrgn-btm10">
                  <label>
                    Last Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    className={
                      this.hasErrors(REQUIRED_FIELDS.LAST_NAME)
                        ? "error code-input"
                        : "code-input"
                    }
                    value={this.state.lastName}
                    onChange={(event) => {
                      this.resetErrors(REQUIRED_FIELDS.LAST_NAME);
                      this.setState({ lastName: event.target.value });
                    }}
                  />
                </div>

                <div className="col-md-4 mrgn-btm10">
                  <label>
                    Email <span className="required">*</span>
                  </label>
                  <input
                    disabled="disabled"
                    type="text"
                    className={
                      this.hasErrors(REQUIRED_FIELDS.EMAIL)
                        ? "error code-input"
                        : "code-input"
                    }
                    value={this.state.email}
                    onChange={(event) => {
                      this.resetErrors(REQUIRED_FIELDS.EMAIL);
                      this.setState({ lastName: event.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="filter-footer">
            {userId && (
              <button onClick={this.submitUser} className="filter-save-button">
                Update
              </button>
            )}
            <button
              onClick={() => {
                this.props.history.push(APP_ROUTES.USER_DASHBOARD);
              }}
              className="filter-cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUser,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(Profile));
