import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { LOCAL_STORAGE_KEYS, RECORDS_PER_PAGE } from "../../constants/common";
import DatePicker from "react-datepicker";
import { AsyncPaginate } from "react-select-async-paginate";
import { patientService } from "../../services/patients";
import { insuranceService } from "../../services/insurance";
import { assistantService } from "../../services/assistants";
import { facilityService } from "../../services/facilities";
import { practiceService } from "../../services/practices";
import { userService } from "../../services/users";
import axios from "axios";

class ScanFlowFilters extends React.Component {
  static propTypes = {
    exportBills: PropTypes.func.isRequired,
    exportEnabled: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    serviceDate: PropTypes.string.isRequired,
    serviceEndDate: PropTypes.string.isRequired,
    setServiceDate: PropTypes.func.isRequired,
    setServiceEndDate: PropTypes.func.isRequired,
    patient: PropTypes.object.isRequired,
    setPatient: PropTypes.func.isRequired,
    assistant: PropTypes.object.isRequired,
    setAssistant: PropTypes.func.isRequired,
    assignSorting: PropTypes.string.isRequired,
    setAssignSorting: PropTypes.func.isRequired,
    billStatus: PropTypes.string.isRequired,
    setBillStatus: PropTypes.func.isRequired,
    facility: PropTypes.object.isRequired,
    setFacility: PropTypes.func.isRequired,
    assignReg: PropTypes.string.isRequired,
    setAssignReg: PropTypes.func.isRequired,
    practice: PropTypes.object.isRequired,
    setPractice: PropTypes.func.isRequired,
    regStatus: PropTypes.string.isRequired,
    setRegStatus: PropTypes.func.isRequired,
    assignCode: PropTypes.string.isRequired,
    setAssignCode: PropTypes.func.isRequired,
    codingStatus: PropTypes.string.isRequired,
    setCodingStatus: PropTypes.func.isRequired,
    assignCharge: PropTypes.object.isRequired,
    setAssignCharge: PropTypes.func.isRequired,
    chargeStatus: PropTypes.string.isRequired,
    setChargeStatus: PropTypes.func.isRequired,
    assignClaim: PropTypes.object.isRequired,
    setAssignClaim: PropTypes.func.isRequired,
    claimStatus: PropTypes.object.isRequired,
    setClaimStatus: PropTypes.func.isRequired,
    hospContract: PropTypes.string.isRequired,
    setHospContract: PropTypes.func.isRequired,
    createdBy: PropTypes.string.isRequired,
    insurance: PropTypes.string.isRequired,
    setCreatedBy: PropTypes.func.isRequired,
    setInsurance: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.cancel = "";
  }

  loadPatient = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return patientService
      .getPatients({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((patient) => ({
          value: patient.id,
          label: `${patient.first_name} ${patient.last_name}`,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadAssistant = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return assistantService
      .getAssistants({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((assistant) => ({
          value: assistant.assistant_id,
          label: `${assistant.first_name} ${assistant.last_name}`,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadFacility = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return facilityService
      .getFacilities({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((facility) => ({
          value: facility.id,
          label: facility.facility_name,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadPractices = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return practiceService
      .getPractices({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((practice) => ({
          value: practice.id,
          label: practice.paytoname,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadUsers = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return userService
      .getUsers({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((user) => ({
          value: user.id,
          label: `${user.name} ${user.last_name}`,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  loadInsurance = (search, prevOptions) => {
    let options;

    if (search) {
      options = {
        search,
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    } else {
      options = {
        page: prevOptions.length / RECORDS_PER_PAGE + 1,
      };
    }

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    return insuranceService
      .getInsurances({ params: options }, this.cancel.token)
      .then((response) => {
        const options = response.data.map((insurance) => ({
          value: insurance.id,
          label: `${insurance.description}`,
        }));

        return {
          options: options,
          hasMore: response.records > prevOptions.length + RECORDS_PER_PAGE,
        };
      });
  };

  resetFilter = () => {
    this.props.setServiceDate("");
    this.props.setServiceEndDate("");
    this.props.setBillStatus("");
    this.props.setRegStatus("");
    this.props.setChargeStatus("");
    this.props.setCodingStatus("");
    this.props.setClaimStatus("");
    this.props.setHospContract("");
    this.props.setCreatedBy("");
    this.props.setAssistant({
      value: "",
      label: "Select Assistant",
    });
    this.props.setFacility({
      value: "",
      label: "Select Facility",
    });
    this.props.setPatient({
      value: "",
      label: "Select Patient",
    });
    this.props.setPractice({
      value: "",
      label: "Select Practice",
    });
    this.props.setAssignCharge({
      value: "",
      label: "Select Assign Charge",
    });
    this.props.setAssignSorting({
      value: "",
      label: "Select Assign Sorting",
    });
    this.props.setAssignReg({
      value: "",
      label: "Select Assign Reg",
    });
    this.props.setAssignCode({
      value: "",
      label: "Select Assign Code",
    });
    this.props.setCreatedBy({
      value: "",
      label: "Select Created By",
    });
    this.props.setInsurance({
      value: "",
      label: "Select Insurance",
    });
    this.props.setAssignClaim(
      {
        value: "",
        label: "Select Assign Claim",
      },
      -1
    );
  };

  render() {
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    return (
      <>
        <div
          style={{
            minHeight: "286px",
          }}
          className="custom-class"
        >
          <div className="scan-flow-filters-column">
            <label>Service Start Date</label>
            <DatePicker
              isClearable
              className="filter-input"
              selected={this.props.serviceDate}
              onChange={(date) => this.props.setServiceDate(date)}
            />
          </div>

          <div className="scan-flow-filters-column">
            <label>Service End Date</label>
            <DatePicker
              isClearable
              className="filter-input"
              selected={this.props.serviceEndDate}
              onChange={(edate) => this.props.setServiceEndDate(edate)}
            />
          </div>
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Bill Status</label>
              <select
                className="filter-input"
                value={this.props.billStatus}
                onChange={(event) =>
                  this.props.setBillStatus(event.target.value)
                }
              >
                <option value="">Bill Status</option>
                <option value="billable">Billable</option>
                <option value="non billable">Non Billable</option>
                <option value="hospital contract">Hospital Contract</option>
                <option value="hospital contract second scrub">
                  Hospital Contract Second Scrub
                </option>
                <option value="bill to patient">Bill to Patient</option>
                <option value="bill to physician">Bill to Physician</option>
              </select>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Registration Status</label>
              <select
                className="filter-input"
                value={this.props.regStatus}
                onChange={(event) =>
                  this.props.setRegStatus(event.target.value)
                }
              >
                <option value="">Registration Status</option>
                <option value="not started">NOT STARTED</option>
                <option value="completed">COMPLETED</option>
              </select>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Charge Status</label>
              <select
                value={this.props.chargeStatus}
                className="filter-input"
                onChange={(event) =>
                  this.props.setChargeStatus(event.target.value)
                }
              >
                <option value="">Charge Status</option>
                <option value="not started">NOT STARTED</option>
                <option value="ready to submit">READY TO SUBMIT</option>
              </select>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Coding Status</label>
              <select
                className="filter-input"
                value={this.props.codingStatus}
                onChange={(event) =>
                  this.props.setCodingStatus(event.target.value)
                }
              >
                <option value="">Coding Status</option>
                <option value="not started">NOT STARTED</option>
                <option value="3m">3M</option>
                <option value="ready to bill">READY TO BILL</option>
                <option value="op request">OP Request</option>
                <option value="in house coding">IN HOUSE CODING</option>
              </select>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Claim Status</label>
              <select
                value={this.props.claimStatus}
                className="filter-input"
                onChange={(event) =>
                  this.props.setClaimStatus(event.target.value)
                }
              >
                <option value="">Claim Status</option>
                <option value="not started">NOT STARTED</option>
                <option value="submitted">SUBMITTED</option>
                <option value="corrected claim">CORRECTED CLAIM</option>
                <option value="paper claim">PAPER CLAIM</option>
                <option value="reconsideration">RECONSIDERATION</option>
                <option value="followup">FOLLOW-UP</option>
                <option value="appeal 1">APPEAL 1</option>
                <option value="appeal 2">APPEAL 2</option>
                <option value="hold to bill">HOLD TO BILL</option>
                <option value="bill patient">BILL PATIENT</option>
                <option value="bill hospital">BILL HOSPITAL</option>
                <option value="bill physician">BILL PHYSICIAN</option>
                <option value="review adjustment">REVIEW ADJUSTMENT</option>
                <option value="closed">CLOSED</option>
                <option value="closed non-bill">CLOSED NON-BILL</option>
                <option value="closed adjustment">CLOSED ADJUSTMENT</option>
                <option value="closed hc-2nd scrub">CLOSED HC-2nd Scrub</option>
              </select>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Patient</label>
              <AsyncPaginate
                placeholder="-- Patient --"
                value={this.props.patient}
                loadOptions={this.loadPatient}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Patient",
                  },
                ]}
                onChange={(value) => this.props.setPatient(value)}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Assistant</label>
              <AsyncPaginate
                placeholder="-- Assistant --"
                value={this.props.assistant}
                loadOptions={this.loadAssistant}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Assistant",
                  },
                ]}
                onChange={(value) => this.props.setAssistant(value)}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Facility</label>
              <AsyncPaginate
                placeholder="-- Facility --"
                value={this.props.facility}
                loadOptions={this.loadFacility}
                defaultOptions={[
                  {
                    value: "",
                    label: "All Facility",
                  },
                ]}
                onChange={(value) => this.props.setFacility(value)}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Practice</label>
              <AsyncPaginate
                placeholder="-- Facility --"
                value={this.props.practice}
                loadOptions={this.loadPractices}
                defaultOptions={[
                  {
                    value: "",
                    label: "All Practice",
                  },
                ]}
                onChange={(value) => this.props.setPractice(value)}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Assign Charge</label>
              <AsyncPaginate
                placeholder="-- Assign Charge --"
                value={this.props.assignCharge}
                loadOptions={this.loadUsers}
                onChange={(value) => this.props.setAssignCharge(value)}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Assign Charge",
                  },
                ]}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Assign Claim</label>
              <AsyncPaginate
                placeholder=""
                value={this.props.assignClaim}
                loadOptions={this.loadUsers}
                onChange={(value) => this.props.setAssignClaim(value)}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Assign Claim",
                  },
                ]}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Assign Sorting</label>
              <AsyncPaginate
                placeholder=""
                value={this.props.assignSorting}
                loadOptions={this.loadUsers}
                onChange={(value) => this.props.setAssignSorting(value)}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Assign Sorting",
                  },
                ]}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Assign Registration</label>
              <AsyncPaginate
                placeholder=""
                value={this.props.assignReg}
                loadOptions={this.loadUsers}
                onChange={(value) => this.props.setAssignReg(value)}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Assign Reg",
                  },
                ]}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Assign Coder</label>
              <AsyncPaginate
                placeholder=""
                value={this.props.assignCode}
                loadOptions={this.loadUsers}
                onChange={(value) => this.props.setAssignCode(value)}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Assign Coder",
                  },
                ]}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Hospital Contract</label>
              <select
                name="hosp_contract"
                id="hosp_contract2"
                value={this.props.hospContract}
                className="filter-input"
                onChange={(event) =>
                  this.props.setHospContract(event.target.value)
                }
              >
                <option value="">Hospital Contract</option>
                <option value="non-contracted">Non Contracted</option>
                <option value="billed hospital">Billed Hospital</option>
                <option value="payment received">Payment Recived</option>
              </select>
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Created By</label>
              <AsyncPaginate
                placeholder=""
                value={this.props.createdBy}
                loadOptions={this.loadUsers}
                onChange={(value) => this.props.setCreatedBy(value)}
                defaultOptions={[
                  {
                    value: "",
                    label: "Select Created By",
                  },
                ]}
              />
            </div>
          )}
          {loggedInUser.type !== "assistant" && (
            <div className="scan-flow-filters-column">
              <label>Insurance Company</label>
              <AsyncPaginate
                placeholder=""
                value={this.props.insurance}
                loadOptions={this.loadInsurance}
                onChange={(value) => this.props.setInsurance(value)}
                defaultOptions={[
                  {
                    value: "",
                    label: "All Insurance",
                  },
                ]}
              />
            </div>
          )}
        </div>
        <div className="filter-footer-claim">
          <button
            style={{
              width: "72px",
            }}
            type="submit"
            onClick={this.props.exportBills}
            disabled={!this.props.exportEnabled()}
            className={
              this.props.exportEnabled()
                ? "filter-footer-button"
                : "filter-footer-disabled-button"
            }
          >
            Export
          </button>
          <button
            style={{
              width: "72px",
            }}
            type="submit"
            onClick={this.resetFilter}
            className="filter-footer-button"
          >
            Refresh
          </button>
          <button
            style={{
              width: "72px",
            }}
            type="submit"
            onClick={this.props.search}
            className="filter-footer-button"
          >
            Search
          </button>
        </div>
      </>
    );
  }
}

export default withRouter(ScanFlowFilters);
