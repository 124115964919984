import React from "react";
import {
  APP_ROUTES,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { deleteAssistant, getRegionAssistants } from "../../actions/assistant";
import logo from "../../../assets/images/universal-logo.png";
import { formatDate } from "../../utils/date";

class AccountDetailDashboard extends React.Component {
  state = {
    loading: false,
    currentPage: 1,
    searchByName: "",
    searched: false,
    searchedAssistants: [],
    searchedTotalRecords: 0,
  };

  componentDidMount() {
    this.setState({ loading: true });
    /*const regionId = this.props.regionId || this.props.location.state.regionId;
        const options = {
            page: this.state.currentPage,
        };
        this.props.getRegionAssistants(regionId, {params: options})
            .then(res => {
                this.setState({loading: false});
            });*/
  }

  showAdd = () =>
    this.props.history.push({
      pathname: APP_ROUTES.ADD_ASSISTANT,
      state: {
        activePage: "surgical_assistants",
        regionId: this.props.regionId || this.props.location.state.regionId,
      },
    });

  edit = (invoiceId) =>
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_ASSISTANT,
      state: {
        activePage:
          this.props.activePage || this.props.location.state.activePage,
        invoiceId,
        regionId: this.props.regionId || this.props.location.state.regionId,
      },
    });
  list = () => {
    const { assistants = [] } = this.props;
    return assistants.map((assistant) => {
      return [
        assistant.id,
        `${assistant.name} ${assistant.last_name}`,
        assistant.email,
        assistant.type,
        assistant.is_active === "1" ? "Active" : "InActive",
        assistant.id,
      ];
    });
  };

  delete = (assistant) => {
    this.props.deleteAssistant(assistant).then((response) => {
      this.goToPage(1);
    });
  };

  render() {
    const activePage =
      this.props.activePage || this.props.location.state.activePage;
    /*const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );*/
    //const { searched, searchedAssistants, searchedTotalRecords } = this.state;
    return (
      <div className="main_container">
        <div
          className="col-md-3 custom-sidebar-menu left_col"
          style={{
            minHeight: "100vh",
          }}
        >
          <Sidebar activePage={activePage} />
        </div>
        <TopNavbar />
        <div
          style={{
            minHeight: "830px",
          }}
          className="right-col"
        >
          <div
            style={{
              borderBottom: "3px solid #4c638c",
            }}
            className="filter-footer"
          >
            <button
              type="submit"
              onClick={this.send}
              className="filter-footer-button"
            >
              Send Invoice
            </button>
            <button
              type="submit"
              onClick={this.cancel}
              className="filter-footer-button"
            >
              Cancel
            </button>
          </div>
          <table
            style={{
              width: "100%",
              border: "0",
              margin: "margin: 0 auto",
            }}
          >
            <tr>
              <td
                style={{
                  height: "90px",
                  textAlign: "center",
                  verticalAlign: "middle",
                }}
              >
                <img src={logo} width="200" height="68" alt="" />
              </td>
            </tr>
            <tr>
              <td>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #cfcfcf",
                    margin: "0 auto",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          width: "3%",
                        }}
                      >
                        <strong>Address:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          width: "39.5%",
                        }}
                      >
                        1 Sugar Creek Center Blvd. Suite 618 Sugar Land, Texas
                        77478
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #cfcfcf",
                    margin: "0 auto",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                        }}
                      >
                        <strong>Bill To:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                        }}
                      ></td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        <strong>Invoice #:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        1234
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "right",
                        }}
                      >
                        <strong>Date:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        {formatDate(new Date())}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #cfcfcf",
                    margin: "0 auto",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                        }}
                      >
                        <strong>Payable to:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                        }}
                      >
                        Universal Surgical Assistants
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        <strong>Email:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                        }}
                      >
                        accounting@universalsa.com
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "right",
                        }}
                      >
                        <strong>Phone:</strong>
                      </td>
                      <td
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "14px",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                        }}
                      >
                        832-655-4141
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #cfcfcf",
                    margin: "0 auto",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        colSpan="5"
                        style={{
                          background: "#4c638c",
                          color: "#fff",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "22px",
                          fontWeight: "300",
                          height: "45px",
                          verticalAlign: "middle",
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;Invoice
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  style={{
                    width: "100%",
                    border: "1px solid #cfcfcf",
                    margin: "0 auto",
                  }}
                >
                  <tbody>
                    <tr>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          borderBottom: "1px solid #cfcfcf",
                        }}
                      >
                        <strong>Account #</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          borderBottom: "1px solid #cfcfcf",
                        }}
                      >
                        <strong>DOS</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                          borderBottom: "1px solid #cfcfcf",
                          width: "80px",
                        }}
                      >
                        <strong>Patient</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                          borderBottom: "1px solid #cfcfcf",
                          width: "80px",
                        }}
                      >
                        <strong>DOB</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "left",
                          borderBottom: "1px solid #cfcfcf",
                        }}
                      >
                        <strong>Procedure</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                          borderBottom: "1px solid #cfcfcf",
                          width: "80px",
                        }}
                      >
                        <strong>Insurance</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                          borderBottom: "1px solid #cfcfcf",
                          width: "80px",
                        }}
                      >
                        <strong>Assistant</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                          borderBottom: "1px solid #cfcfcf",
                          width: "80px",
                        }}
                      >
                        <strong>Surgeon</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                          borderBottom: "1px solid #cfcfcf",
                          width: "80px",
                        }}
                      >
                        <strong>Facility</strong>
                      </th>
                      <th
                        style={{
                          color: "#6f6f6f",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "15px",
                          fontWeight: "500",
                          height: "30px",
                          verticalAlign: "middle",
                          textAlign: "center",
                          borderBottom: "1px solid #cfcfcf",
                          width: "80px",
                        }}
                      >
                        <strong>Total Bill</strong>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table
                  style={{
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color: "#4c638c",
                          fontFamily: "Segoe, Segoe UI, Verdana",
                          fontSize: "30px",
                          fontWeight: "700",
                          height: "50px",
                          verticalAlign: "middle",
                          textAlign: "right",
                        }}
                      >
                        Total : 0.00
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#4c638c",
                  fontFamily: "Segoe, Segoe UI, Verdana",
                  fontSize: "20px",
                  fontWeight: "700",
                  height: "50px",
                  verticalAlign: "bottom",
                  textAlign: "center",
                }}
              >
                Thank you for your Business
              </td>
            </tr>
            <tr>
              <td
                style={{
                  height: "100px",
                  textAlign: "center",
                  verticalAlign: "bottom",
                }}
              >
                <img src={logo} width="200" height="68" alt="" />
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: "#6f6f6f",
                  fontFamily: "Segoe, Segoe UI, Verdana",
                  fontSize: "13px",
                  fontWeight: "400",
                  height: "50px",
                  textAlign: "center",
                }}
              >
                support@coremaxsurgical.com <br />© Coremax Cloud
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assistants: state.assistantReducer.assistants,
    totalRecords: state.assistantReducer.totalAssistants,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRegionAssistants,
      deleteAssistant,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountDetailDashboard));
