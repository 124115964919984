import { apiClient } from "./apiClients";
import { API_CONSTANTS } from "../constants/api";
import { handleError, handleResponse } from "./GenericResponseHandler";
import { createNotification } from "../utils/notificationManager";
import {
    NOTIFICATION_TYPES,
} from "../constants/common";

function getFile(id) {
    return apiClient()
        .get(API_CONSTANTS.FACESHEET_DOWNLOAD + id, null)
        .then(handleResponse)
        .catch(handleError);
}

function downloadFile(id) {
    return apiClient()
        .get(API_CONSTANTS.FACESHEET_DOWNLOAD + id, null)
        .then(handleResponse => {
            let blob = new Blob([Buffer.from(handleResponse.data.data.content, "base64")], { type: "octet/stream" })
            let url = window.URL.createObjectURL(blob);

            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";

            a.href = url;
            a.download = handleResponse.data.data.filename;
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(handleError => {
            createNotification(
                NOTIFICATION_TYPES.ERROR,
                `Unable to download document ${handleError.message}`
            );
            return handleError;
        });
}

export const FileStorageService = {
    getFile,
    downloadFile
}