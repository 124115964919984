import React from "react";
import {
  APP_ROUTES,
  FACILITY_LISTING_COLUMNS,
  LOCAL_STORAGE_KEYS,
} from "../../constants/common";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import { bindActionCreators } from "redux";
import { fetchFacilities } from "../../actions/facility";
import FacilityListingTable from "../atom/FacilityListingTable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { facilityService } from "../../services/facilities";
import PropTypes from "prop-types";

class FacilityDashboard extends React.Component {
  static propTypes = {
    totalRecords: PropTypes.number.isRequired,
    fetchFacilities: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    currentPage: 1,
    searchByName: "",
    searched: false,
    searchedFacilities: [],
    searchedTotalRecords: 0,
  };

  componentWillMount() {
    this.setState({ loading: true });
    const options = {
      page: this.state.currentPage,
    };
    this.props.fetchFacilities({ params: options }).then((res) => {
      this.setState({ loading: false });
    });
  }

  setLoading = (flag) => {
    this.setState({ loading: flag });
  };

  setCurrentPage = (num) => {
    this.setState({ currentPage: num });
  };

  goToPage = (page) => {
    this.setLoading(true);
    this.setCurrentPage(page);

    const options = {
      page: page,
    };
    this.props.fetchFacilities({ params: options }).then((res) => {
      this.setLoading(false);
    });
  };

  showAdd = () => this.props.history.push(APP_ROUTES.ADD_FACILITY);

  resetFacilitySearch = () => {
    this.setState({
      searchByName: "",
      searchedFacilities: [],
      searched: false,
      searchedTotalRecords: 0,
    });
  };

  searchFacility = () => {
    const { searchByName } = this.state;
    let options = {};
    if (searchByName) {
      options = { ...options, search: searchByName };
    }
    this.setState({
      searched: true,
      loading: true,
    });
    this.setCurrentPage(1);
    facilityService.getFacilities({ params: options }).then((response) => {
      this.setState({
        searchedTotalRecords: parseInt(response.records),
        searchedFacilities: response.data.map((facility) => {
          return [
            facility.id,
            facility.facility_name.toUpperCase(),
            facility.address,
            facility.contact_phone,
            facility.contact_fax,
            facility.npi,
            facility.id,
          ];
        }),
      });
      this.setState({ loading: false });
    });
  };

  list = () => {
    const { facilities = [] } = this.props;
    //var invoice_req = "";
    return facilities.map((facility) => {
      /*if (facility.invoice_req != null) {
        invoice_req = facility.invoice_req;
      } else {
        invoice_req = "";
      }*/
      return [
        facility.id,
        facility.facility_name.toUpperCase(),
        facility.address,
        facility.contact_phone,
        facility.contact_fax,
        facility.npi,
        // invoice_req,
        facility.id,
      ];
    });
  };

  showManageFacility = (facilityId) =>
    this.props.history.push({
      pathname: APP_ROUTES.EDIT_FACILITY,
      state: {
        facilityId,
      },
    });

  deleteFacility = (facility) => {
    facilityService.deleteFacility(facility).then((response) => {
      this.goToPage(1);
    });
  };

  render() {
    const { searched, searchedFacilities, searchedTotalRecords } = this.state;
    const activePage =
      this.props.activePage || this.props.location.state.activePage;
    const loggedInUser = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.LOGGED_IN_USER)
    );
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar activePage={activePage} />
        </div>
        <TopNavbar />
        <div
          style={{
            minHeight: "760px",
          }}
          className="right-col"
        >
          <div className="page-title">
            <div className="title_left">
              <h3
                style={{
                  fontFamily:
                    "Helvetica Neue, Roboto, Arial, Droid Sans, sans-serif",
                  fontSize: "24px",
                  fontWeight: "400",
                  lineHeight: "1.471",
                }}
              >
                Facilities
              </h3>
            </div>
          </div>
          {loggedInUser.isAddAccess && (
            <div className="filter-footer-header">
              <button
                onClick={() => {
                  this.showAdd(true);
                }}
                className="filter-button"
              >
                Add New Facility
              </button>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <div
              style={{
                paddingLeft: "0",
              }}
              className="col-md-3 mrgn-btm10"
            >
              <label>Facility Name</label>
              <input
                type="text"
                className="code-input"
                value={this.state.searchByName}
                onChange={(event) => {
                  this.setState({ searchByName: event.target.value });
                }}
              />
            </div>
            <div className="col-md-3 mrgn-btm10">
              <button
                style={{
                  marginTop: "32px",
                  width: "100px",
                  height: "38px",
                  marginRight: "10px",
                }}
                onClick={this.searchFacility}
                className="filter-save-button"
              >
                Search
              </button>
              <button
                style={{
                  marginTop: "32px",
                  width: "100px",
                  height: "38px",
                }}
                onClick={this.resetFacilitySearch}
                className="filter-save-button"
              >
                Reset
              </button>
            </div>
          </div>
          <div className="row">
            <div className="activities-header">
              <FacilityListingTable
                isLoading={this.state.loading}
                currentPage={this.state.currentPage}
                goToPage={this.goToPage}
                edit={this.showManageFacility}
                delete={this.deleteFacility}
                headColumn={FACILITY_LISTING_COLUMNS}
                listing={
                  searched && searchedFacilities.length > 0
                    ? searchedFacilities
                    : this.list()
                }
                totalRecords={
                  searched && searchedTotalRecords > 0
                    ? searchedTotalRecords
                    : this.props.totalRecords
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    facilities: state.facilityReducer.facilities,
    totalRecords: state.facilityReducer.totalFacilities,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchFacilities,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FacilityDashboard));
