import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { scanFlowService } from "../../services/scanFlow";
import {
  APP_ROUTES,
  LOCAL_STORAGE_KEYS,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";
import { withRouter } from "react-router-dom";
import viewIcon from "../../../assets/images/view.png";
import deleteIcon from "../../../assets/images/delete.png";
import Swal from "sweetalert2";
import { activityService } from "../../services/activities";
import { FileStorageService } from "../../services/fileStorage";

const UploadButton = (props) => {
  return (
    <Button color="primary" onClick={() => props.upload(props.type)}>
      Upload New File
    </Button>
  );
};

class UploadDocument extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    eventId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    setModal: PropTypes.func.isRequired,
    goToPage: PropTypes.func.isRequired,
  };

  state = {
    patientId: "",
    files: [],
  };

  componentDidMount() {
    if (this.props.eventId) {
      scanFlowService.getBillingDetail(this.props.eventId).then((response) => {
        this.setState({
          patientId: response.data.patient ? response.data.patient.id : "",
        });

        let file = "";
        if (this.props.type === "facesheet") {
          file =
            response.data.facesheets && response.data.facesheets.length > 0
              ? response.data.facesheets
              : "";
        } else if (this.props.type === "op") {
          file =
            response.data.op && response.data.op.length > 0
              ? response.data.op
              : "";
        } else if (this.props.type === "correspondence") {
          file =
            response.data.insurace_file &&
              response.data.insurace_file.length > 0
              ? response.data.insurace_file
              : "";
        }

        this.setState({ files: file });
      });
    }
  }

  handleClick = () => {
    this.refs.hiddenFileInput.click();
  };

  parseFile = (file) => {
    const fileUrl = file.split("/");
    return fileUrl[fileUrl.length - 1];
  };

  upload = (event, eventId, patientId, type) => {
    const filesUploaded =
      type !== "facesheet" ? event.target.files : event.target.files[0];

    if (type !== "facesheet") {
      for (let i = 0; i < filesUploaded.length; i++) {
        const formData = new FormData();
        formData.append("event_id", eventId);
        formData.append("patient_id", patientId);
        formData.append("file", filesUploaded[i]);
        formData.append("type", type);
        formData.append("api_type", "react");

        scanFlowService
          .uploadDocument(formData)
          .then((res) => {
            if (res.data.success) {
              this.props.history.push(APP_ROUTES.SCAN_FLOW_DASHBOARD);
            } else {
              this.props.setModal(false);
              if (i === filesUploaded.length - 1) {
                createNotification(NOTIFICATION_TYPES.SUCCESS, res.message);
              }
              this.props.goToPage(1);
            }
          })
          .catch((err) => {
            createNotification(
              NOTIFICATION_TYPES.ERROR,
              `Unable to upload document ${err.message}`
            );
          });
      }
    } else {
      const formData = new FormData();
      formData.append("event_id", eventId);
      formData.append("patient_id", patientId);
      formData.append("file", filesUploaded);
      formData.append("type", type);
      formData.append("api_type", "react");

      scanFlowService
        .uploadDocument(formData)
        .then((res) => {
          if (res.data.success) {
            this.props.history.push(APP_ROUTES.SCAN_FLOW_DASHBOARD);
          } else {
            this.props.setModal(false);
            createNotification(NOTIFICATION_TYPES.SUCCESS, res.message);
            this.props.goToPage(1);
          }
        })
        .catch((err) => {
          createNotification(
            NOTIFICATION_TYPES.ERROR,
            `Unable to upload document ${err.message}`
          );
        });
    }
  };

  showFile = (file) => {
    FileStorageService.downloadFile(file).then(data => {
      console.log(data);
    })
  };

  renderHeadColumn = () => {
    const headColumn = ["File Name", "Type", "Action"];

    return headColumn.map((column) => (
      <th className="listing-table-head-column">{column}</th>
    ));
  };

  deleteFile = (faceSheetId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        activityService
          .deleteActivityFaceSheet(faceSheetId)
          .then((response) => {
            this.props.setModal(false);
            this.props.goToPage(1);
          });
      }
    });
  };

  renderSheetList = () => {
    const { files } = this.state;
    const { type } = this.props;
    return files.map((file) => {
      const fileName = this.parseFile(
        file[`${type === "correspondence" ? "insurance" : type}_file`]
      );
      const sheetDetails = [fileName, type, file.id];
      const item = sheetDetails.map((item, index) => {
        if (index === 2) {
          return (
            <td className="listing-table-column">
              <img
                onClick={() =>
                  this.showFile(item)
                }
                src={viewIcon}
                alt="view"
              />
              <img
                src={deleteIcon}
                onClick={() => {
                  this.deleteFile(item);
                }}
                alt="view"
              />
            </td>
          );
        } else {
          return (
            <td
              className="listing-table-column"
              style={{ color: "black !important" }}
            >
              {item}
            </td>
          );
        }
      });
      return <tr className="listing-table-rows">{item}</tr>;
    });
  };

  render() {
    const { eventId, title, isOpen, setModal, type } = this.props;

    const { patientId, files } = this.state;
    return (
      <div>
        <Modal isOpen={isOpen} toggle={() => setModal(!isOpen)}>
          <ModalHeader toggle={() => setModal(!isOpen)}>{title}</ModalHeader>
          <ModalBody>
            <UploadButton upload={this.handleClick} type={type} />
            <input
              type="file"
              accept="application/pdf"
              ref="hiddenFileInput"
              multiple={type !== "facesheet"}
              onChange={(event) => {
                this.upload(event, eventId, patientId, type);
              }}
              style={{ display: "none" }}
            />
            {files && (
              <div
                style={{
                  paddingTop: "10px",
                }}
              >
                <div className="table-responsive">
                  <table className="listing-table">
                    <thead className="listing-table-head">
                      <tr>{this.renderHeadColumn()}</tr>
                    </thead>
                    <tbody>{this.renderSheetList()}</tbody>
                  </table>
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setModal(!isOpen)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(UploadDocument);
