import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "../organisms/Sidebar";
import TopNavbar from "../organisms/TopNavbar";
import {
  APP_ROUTES,
  NOTIFICATION_TYPES,
} from "../../constants/common";
import { createNotification } from "../../utils/notificationManager";

import { createInsurance, updateInsurance } from "../../actions/insurance";
import { insuranceService } from "../../services/insurance";

const REQUIRED_FIELDS = {
  NAME: "firstName",
};

class ManageInsurance extends React.Component {
  state = {
    errors: [],
    name: "",
    e_payer_id: "",
    m_payer_id: "",
    type_code: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    payer_type: "",
    phone: "",
    fax: "",
  };

  componentDidMount() {
    const insId = this.props.location.state
      ? this.props.location.state.insId
      : "";
    if (insId) {
      insuranceService.getInsuranceDetail(insId).then((response) => {
        this.setState({
          name: response.data.description,
          e_payer_id: response.data.id2,
          m_payer_id: response.data.payor_code,
          payer_type: response.data.category,
          type_code: response.data.insurance_type,
          address: response.data.address1,
          address2: response.data.address2,
          city: response.data.city,
          state: response.data.state,
          zip: response.data.zip,
          phone: response.data.phone,
          fax: response.data.fax,
        });
      });
    }
  }

  hasErrors = (field) => {
    return this.state.errors.includes(field);
  };

  resetErrors = (field) => {
    this.setState({
      errors: this.state.errors.filter((fieldName) => fieldName !== field),
    });
  };

  formatPhone = (str) => {
    if (str) {
      let phone = "";
      str = str.replace(/\D/g, "").substring(0, 10);
      if (str.length < 3) {
        phone += str;
      } else if (str.length < 6) {
        phone += str.substring(0, 3) + "-";
        if (str.length > 3) {
          phone += str.substring(3, str.length);
        }
      } else {
        phone +=
          str.substring(0, 3) +
          "-" +
          str.substring(3, 6) +
          "-" +
          str.substring(6, 10);
      }
      return phone;
    }
    return str;
  };

  validateActivity = () => {
    const { name, errors } = this.state;

    let isValid = true;

    if (name.length === 0) {
      this.setState({ errors: [...errors, REQUIRED_FIELDS.NAME] });
      isValid = false;
    }

    if (!isValid) {
      createNotification(
        NOTIFICATION_TYPES.ERROR,
        "Please select required values"
      );
    }

    return isValid;
  };

  submitInsurance = () => {
    if (!this.validateActivity()) {
      return;
    }
    const { createInsurance, updateInsurance } = this.props;
    const {
      name,
      e_payer_id,
      m_payer_id,
      type_code,
      address,
      address2,
      city,
      zip,
      state,
      payer_type,
      phone,
      fax,
    } = this.state;

    const request = {
      description: name,
      payor_code: e_payer_id,
      id2: m_payer_id,
      insurance_type: type_code,
      category: payer_type,
      address1: address,
      address2: address2,
      city: city,
      state: state,
      zip: zip,
      phone: phone,
      fax: fax,
    };

    const insId = this.props.location.state
      ? this.props.location.state.insId
      : "";
    if (insId) {
      const payload = { ...request, id: insId };
      updateInsurance(payload)
        .then((res) => {
          if (res.response.success) {
            const screen = this.props.location.state
              ? this.props.location.state.screen
              : "";
            if (screen) {
              this.props.history.push(screen);
            } else {
              createNotification(
                NOTIFICATION_TYPES.SUCCESS,
                res.response.message
              );
              this.props.history.push(APP_ROUTES.INSURANCE_DASHBOARD);
            }
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
            //this.props.history.push(APP_ROUTES.USER_DASHBOARD);
          }
        })
        .catch((err) => {
          createNotification(
            NOTIFICATION_TYPES.ERROR,
            `Unable to update insurance ${err.error.response.data.message}`
          );
        });
    } else {
      createInsurance(request)
        .then((res) => {
          if (res.response.success) {
            const screen = this.props.location.state
              ? this.props.location.state.screen
              : "";
            if (screen) {
              this.props.history.push(screen);
            } else {
              createNotification(
                NOTIFICATION_TYPES.SUCCESS,
                res.response.message
              );
              this.props.history.push(APP_ROUTES.INSURANCE_DASHBOARD);
            }
          } else {
            createNotification(NOTIFICATION_TYPES.ERROR, res.response.message);
            //this.props.history.push(APP_ROUTES.USER_DASHBOARD);
          }
        })
        .catch((err) => {
          createNotification(
            NOTIFICATION_TYPES.ERROR,
            `Unable to create insurance ${err.error.response.data.message}`
          );
        });
    }
  };

  toggleCheckbox = (name, event) => {
    let obj = {};
    obj[name] = !this.state[name];
    this.setState(obj);
  };

  cancel = () => {
    const screen = this.props.location.state
      ? this.props.location.state.screen
      : "";
    if (screen) {
      this.props.history.push(screen);
    } else {
      this.props.history.push(APP_ROUTES.INSURANCE_DASHBOARD);
    }
  };

  render() {
    const insId = this.props.location.state
      ? this.props.location.state.insId
      : "";
    return (
      <div className="main_container">
        <div className="col-md-3 custom-sidebar-menu left_col">
          <Sidebar />
        </div>
        <TopNavbar />
        <div className="manage-activity-right-col">
          <div className="activity-row">
            <div className="page-title">
              {insId && <h2 className="heading-custom">Edit Insurance</h2>}
              {!insId && <h2 className="heading-custom">Add Insurance</h2>}
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>
                Insurance Name <span className="required">*</span>
              </label>
              <input
                type="text"
                className={
                  this.hasErrors(REQUIRED_FIELDS.NAME)
                    ? "error code-input"
                    : "code-input"
                }
                value={this.state.name}
                onChange={(event) => {
                  this.resetErrors(REQUIRED_FIELDS.NAME);
                  this.setState({ name: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>Electronic Payer ID</label>
              <input
                type="text"
                className="code-input"
                value={this.state.e_payer_id}
                onChange={(event) => {
                  this.setState({ e_payer_id: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>Master Payer ID </label>
              <input
                type="text"
                className="code-input"
                value={this.state.m_payer_id}
                onChange={(event) => {
                  this.setState({ m_payer_id: event.target.value });
                }}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>Type Code </label>
              <input
                type="text"
                className="code-input"
                value={this.state.type_code}
                onChange={(event) => {
                  this.setState({ type_code: event.target.value });
                }}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>Payer Type</label>
              <input
                type="text"
                className="code-input"
                value={this.state.payer_type}
                onChange={(event) => {
                  this.setState({ payer_type: event.target.value });
                }}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>Address </label>
              <input
                type="text"
                className="code-input"
                value={this.state.address}
                onChange={(event) => {
                  this.setState({ address: event.target.value });
                }}
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>Address 2 </label>
              <input
                type="text"
                className="code-input"
                value={this.state.address2}
                onChange={(event) => {
                  this.setState({ address2: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>City </label>
              <input
                type="text"
                className="code-input"
                value={this.state.city}
                onChange={(event) => {
                  this.setState({ city: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>State </label>
              <input
                type="text"
                className="code-input"
                value={this.state.state}
                onChange={(event) => {
                  this.setState({ state: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>Zip Code</label>
              <input
                type="text"
                className="code-input"
                value={this.state.zip}
                onChange={(event) => {
                  this.setState({ zip: event.target.value });
                }}
              />
            </div>
            <div className="col-md-4 mrgn-btm10">
              <label>Phone </label>
              <input
                type="text"
                className="code-input"
                value={this.state.phone}
                onChange={(event) =>
                  this.setState({ phone: this.formatPhone(event.target.value) })
                }
              />
            </div>

            <div className="col-md-4 mrgn-btm10">
              <label>Fax </label>
              <input
                type="text"
                className="code-input"
                value={this.state.fax}
                onChange={(event) =>
                  this.setState({ fax: this.formatPhone(event.target.value) })
                }
              />
            </div>
          </div>
          <div className="filter-footer">
            {insId && (
              <button
                onClick={this.submitInsurance}
                className="filter-save-button"
              >
                Update
              </button>
            )}
            {!insId && (
              <button
                onClick={this.submitInsurance}
                className="filter-save-button"
              >
                Submit
              </button>
            )}
            <button onClick={this.cancel} className="filter-cancel-button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createInsurance,
      updateInsurance,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(ManageInsurance));
