import { apiClient } from "./apiClients";
import { handleResponse, handleError } from "./GenericResponseHandler";
import { API_CONSTANTS } from "../constants/api";

function getAssistants(options, token) {
    return apiClient(token)
        .get(API_CONSTANTS.LIST_ASSISTANTS, options)
        .then(handleResponse)
        .catch(handleError);
}

function getRegionAssistants(region, options) {
   
    if( region !== undefined){
        
    }else{
        region = 0;
    }
    return apiClient()
        .get(API_CONSTANTS.LIST_REGION_ASSISTANTS + region, options)
        .then(handleResponse)
        .catch(handleError);
}

function createAssistant(payload) {
    return apiClient()
        .post(API_CONSTANTS.CREATE_ASSISTANT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function updateAssistant(payload) {
    return apiClient()
        .put(API_CONSTANTS.UPDATE_ASSISTANT, payload)
        .then(handleResponse)
        .catch(handleError);
}

function getCertificates(options) {
    return apiClient()
        .get(API_CONSTANTS.LIST_CERTIFICATES, options)
        .then(handleResponse)
        .catch(handleError);
}

function deleteAssistant(assistant) {
    return apiClient()
        .delete(API_CONSTANTS.DELETE_ASSISTANT + assistant)
        .then(handleResponse)
        .catch(handleError);
}

function getAssistantDetail(assistant) {
    return apiClient()
        .get(API_CONSTANTS.ASSISTANT_DETAIL + assistant)
        .then(handleResponse)
        .catch(handleError);
}

export const assistantService = {
    getAssistants,
    getRegionAssistants,
    createAssistant,
    updateAssistant,
    getCertificates,
    deleteAssistant,
    getAssistantDetail,
};
