import React from "react";
import Iframe from "react-iframe";
import { LOCAL_STORAGE_KEYS } from "../../constants/common";
import "../../../sass/file.scss";

export default class FileDisplay extends React.Component {
  render() {
    const file = localStorage.getItem(LOCAL_STORAGE_KEYS.FILE);
    return (
      <div id="wrapper">
        <Iframe
          url={file}
          width="100%"
          height="1600px"
          id="myId"
          display="initial"
          position="relative"
        />
      </div>
    );
  }
}
