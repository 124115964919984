import { LOGS } from '../constants/actions';
import { logsService } from '../services/logs';

export const fetchLogs = options => dispatch => {
    
    return new Promise((resolve, reject) => {
        logsService
            .getLogs(options)
            .then(response => {
                dispatch({
                    type: LOGS.FETCH_LOGS_SUCCESS,
                    data: response,
                });
                resolve({ response });
            })
            .catch(error => {
                reject({ error });
            });
    });
};
